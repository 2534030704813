import { EDIT_PROSPECTION, GENERATE_PROSPECTION, GET_ALL_PROSPECTIONS, GET_PROSPECTIONS } from "../actions/action-types";

// Générer prospection
export function GenerateProspectionReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case GENERATE_PROSPECTION:
            return action.payload;
        default:
            return state;
    }
}

// Get all prospections
export function GetAllProspections(state = {
    alert: {},
    events: []
}, action) {
    switch (action.type) {
        case GET_ALL_PROSPECTIONS:
            return action.payload;
        default:
            return state;
    }
}

// Get prospections
export function GetProspections(state = {
    alert: {},
    totalQuarter: [],
    prospections: []
}, action) {
    switch (action.type) {
        case GET_PROSPECTIONS:
            return action.payload;
        default:
            return state;
    }
}

// Edit prospection
export function EditProspectionReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case EDIT_PROSPECTION:
            return action.payload;
        default:
            return state;
    }
}