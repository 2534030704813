import axios from "axios";
import { config, getDomain } from "../../helpers";
import { ADD_STREET, DEL_STREET, EDIT_STREET, GET_STREET, GET_STREETS } from "../action-types";

// Obtenir les évènements
export function getStreets() {
    return function (dispatch) {
        axios.post(`${getDomain}/api/streets`, {}, config)
            .then(res => {
                let alert = {};
                let streets = [];

                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Rues reçues', content: "Les rues ont bien été reçues"};
                    streets = res.data.streets;
                } else {
                    alert = {type: 'error', title: 'Aucune rue', content: "Aucune rue n'a été créée pour le moment."};
                }

                dispatch({
                    type: GET_STREETS,
                    payload: {
                        alert: alert,
                        streets: streets
                    }
                })
            })
    }
}

// Détails d'un évènement
export function getStreet({ id }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/streets/${id}`, {}, config)
            .then(res => {
                let alert = {};
                let street = [];

                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Rues reçues', content: "Les rues ont bien été reçues"};
                    street = res.data.street;
                } else {
                    alert = {type: 'error', title: 'Erreur survenue', content: "Une erreur est survenue, veuillez contacter Matthew."};
                }

                dispatch({
                    type: GET_STREET,
                    payload: {
                        alert: alert,
                        street: street
                    }
                })
            })
    }
}

// Ajout d'un évènement
export function addStreet({ form, svg })  {
    return function (dispatch) {
        axios.post(`${getDomain}/api/street/add`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "name": form.name,
            "quarter1": form.quarter1,
            "quarter2": form.quarter2,
            "quarter3": form.quarter3,
            "quarter4": form.quarter4,
            "quarter5": form.quarter5,
            "path1": form.path1,
            "path2": form.path2,
            "path3": form.path3,
            "path4": form.path4,
            "path5": form.path5,
        }, config)
            .then(res => {
                let alert = {};

                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Rue créée', content: "La rue a bien été créé"};
                } else {
                    switch (res.data.type) {
                        case 'empty':
                            alert = {
                                type: 'error',
                                title: 'Erreur formulaire', 
                                content: "Veuillez remplir tous les champs obligatoires"
                            }
                            break;
                        case 'not-right':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: ADD_STREET,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
} 

// Edition d'un évènement
export function editStreet({ form, id }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/street/edit/${id}`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "name": form.name,
            "quarter1": form.quarter1,
            "quarter2": form.quarter2,
            "quarter3": form.quarter3,
            "quarter4": form.quarter4,
            "quarter5": form.quarter5,
            "path1": form.path1,
            "path2": form.path2,
            "path3": form.path3,
            "path4": form.path4,
            "path5": form.path5,
        }, config)
            .then(res => {
                let alert = {};

                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Rue modifiée', content: "La rue a bien été modifiée"};
                } else {
                    switch (res.data.type) {
                        case 'empty':
                            alert = {
                                type: 'error',
                                title: 'Erreur formulaire', 
                                content: "Veuillez remplir tous les champs obligatoires"
                            }
                            break;
                        case 'not-right':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: EDIT_STREET,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}

// Suppression d'un évènement
export function deleteStreet({ id }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/street/delete/${id}`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
        }, config)
            .then(res => {
                let alert = {};
                
                if (res.data.status == "success") {
                    alert = {
                        type: 'success',
                        title: 'Rue supprimée', 
                        content: "La rue a bien été supprimée"
                    };
                } else {
                    switch (res.data.type) {
                        case "not-right":
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            };
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: DEL_STREET,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}