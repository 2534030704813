import React, { Component } from "react";

class PolConf extends Component {
    render() {
        return <section className="annexe">
        <header className="annexe__header">
            <h2 className="annexe__ttl">Politique de confidentialité</h2>
            <a href="/" className="btn">Retour à l'accueil</a>
        </header>
        <div className="annexe__container">
            <h3 className="annexe__subttl">LE TRAITEMENT DE VOS DONNÉES</h3>
            <p className="annexe__text">
                Votre vie privée est l'une de nos priorité.
            </p>
            <p className="annexe__text">
                Nous nous engageons à protéger la vie privée des visiteurs et des utilisateurs de nos offres en conformité avec la loi Informatique et Libertés du 6 janvier 1978 modifiée ainsi qu'avec le règlement 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD)
            </p>
            <p className="annexe__text">
                A ce titre, nous respections les principes suivants:
            </p>
            <ul className="annexe__list annexe__text">
            <li className="annexe__item">
                    - Nous collections les données dont nous avons besoin;
                </li>
                <li className="annexe__item">
                    - Nous traitons vos données pour des finalités explicites, légitimes et déterminées;
                </li>
                <li className="annexe__item">
                    - Nous conservons vos données pendant la durée nécessaire à la réalisation du traitement pour lesquelles elles ont été collectées ou, si tel est le cas, pendant la durée fixée par la loi;
                </li>
                <li className="annexe__item">
                    - Nous veillons à mettre en œuvre les mesures de sécurité adaptées afin d’assurer un niveau de protection élevé à vos données.
                </li>
            </ul>
            <p className="annexe__text">
                Notre Politique Vie Privée a pour objectif de vous informer de manière transparente sur la façon dont nous collectons et utilisons vos données ainsi que sur vos droits.
            </p>
            <p className="annexe__text">
                En utilisant f2r.projet-matthew.fr, vous déclarez accepter les termes de la présente Politique. Cette dernière est susceptible d’être modifiée à tout moment, aussi nous vous invitons à consulter cette page régulièrement.
            </p>
            <p className="annexe__text">
                Conformément à la loi Informatique et Liberté n°78-17 modifiée et au Règlement 2016/679 du Parlement européen et du Conseil du 27 avril 2016, vous disposez d'un droit à la limitation du traitement de leurs données, d’un droit d'opposition pour motif légitime, d'accès, de portabilité, et de rectification et de suppression des données à caractère personnel vous concernant, qu'ils peuvent exercer sur simple demande envoyée à l’adresse : <strong>m.guimbretiere937@gmail.com</strong>
            </p>
            <br />
            <h3 className="annexe__subttl">COOKIES ET AUTRES TRACEURS</h3>
            <h4 className="annexe__subsubttl">Qu'est-ce qu'un cookie ?</h4>
            <p className="annexe__text">Un cookie est un petit fichier texte susceptible d'être déposé et enregistré dans un espace du disque dur de votre terminal (ordinateur, tablette, smartphone ou tout autre appareil optimisé pour internet) lorsque vous visitez un service en ligne. Il permet à l'émetteur du cookie d'identifier le terminal dans lequel il a été enregistré et de garder en mémoire, pendant la durée de validité de ce cookie (13 mois maximum), certaines informations relatives à votre parcours afin, par exemple, de simplifier votre navigation sur un site internet, de sécuriser votre connexion ou d'adapter le contenu d'une page à vos centres d'intérêts.</p>
            <p className="annexe__text">D'autres technologies aux fonctionnalités similaires peuvent également être utilisées notamment dans des environnements qui ne supportent pas les cookies, tels que par exemple les applications mobiles. Le terme de « cookies » utilisé dans notre Politique de confidentialité s'applique à l'ensemble de ces technologies.</p>
            <p className="annexe__text">Ces cookies peuvent provenir de différents émetteurs :</p>
            <ul className="annexe__list">
                <li className="annexe__item">
                    <p className="annexe__text"><strong>Les cookies Editeur :</strong>Il s'agit des cookies susceptibles d'être déposés par l'Editeur sur votre terminal lors de votre navigation sur la Plateforme et/ou l'Application. Ces cookies nous permettent notamment de personnaliser, d'améliorer continuellement votre expérience sur la Plateforme et/ou l'Application.</p>
                </li>
                <li className="annexe__item">
                    <p className="annexe__text"><strong>Les cookies tiers :</strong>Il s'agit des cookies susceptibles d'être déposés par des sociétés tierces (par exemple des régies publicitaires, des réseaux sociaux, des partenaires de l'Editeur, etc.) sur votre terminal lors de votre navigation sur la Plateforme et/ou l'Application. Ils permettent notamment à ces sociétés tierces d'identifier vos centres d'intérêt, de mesurer le trafic, et de personnaliser l'offre publicitaire qui pourrait vous être adressée sur et/ou en dehors de la Plateforme et/ou l'Application. Nous vous invitons à consulter les politiques de protection de la vie privée de ces sociétés tierces afin de prendre connaissance notamment de la durée de validité des cookies qu'ils émettent.</p>
                </li>
            </ul>
            <h4 className="annexe__subsubttl">Quels sont les types de cookies utilisés par l'Editeur ?</h4>
            <ul className="annexe__list">
                <li className="annexe__item">
                    <p className="annexe__text">
                        <strong>Nécessaire :</strong>
                        </p><p className="annexe__text">
                        Ces cookies sont strictement nécessaires au fonctionnement de la Plateforme et/ou l'Application. Ils vous permettent de naviguer sur la Plateforme et/ou l'Application et d'en utiliser les fonctionnalités.
                        </p>
                        <p className="annexe__text">
                        Si vous avez choisi de désactiver ces cookies via votre navigateur internet, l'accès et/ou l'utilisation de la Plateforme et/ou l'Application pourraient en être altérés. Nous vous conseillons de les laisser activés afin de profiter pleinement de nos services et de votre navigation sur la Plateforme et/ou l'Application.
                        </p>
                    <p></p>
                </li>
                <li className="annexe__item">
                    <p className="annexe__text">
                        <strong>Statistiques :</strong>
                        </p><p className="annexe__text">
                        Ces cookies nous permettent d’établir des statistiques de fréquentation (par exemple, nombre de visites, de pages vues, de rubriques consultées, etc.) et de comprendre l’activité des visiteurs de la Plateforme et/ou l’Application afin d’améliorer les fonctionnalités et l’ergonomie de la Plateforme et/ou l’Application pour rendre votre navigation plus agréable.
                        </p>
                    <p></p>
                </li>
                <li className="annexe__item">
                    <p className="annexe__text">
                        <strong>Publicité :</strong>
                        </p><p className="annexe__text">
                        Ces cookies sont utilisés pour vous proposer des publicités personnalisées sur la Plateforme et/ou l'Application ou sur d'autres sites en fonction de vos centres d'intérêt. Ces cookies sont des cookies tiers.
                        </p>
                        <p className="annexe__text">
                        Ces cookies nous permettent également d'identifier si le téléchargement de notre application provient d'un site partenaire en vue de leur rémunération.
                        </p>
                        <p className="annexe__text">
                        Le fait de refuser les cookies publicitaires n'entraînera pas l'arrêt de la publicité sur la Plateforme et/ou l'Application ou sur les autres sites que vous visitez. Les publicités ne seront toutefois plus personnalisées en fonction de vos centres d'intérêts.
                        </p>
                    <p></p>
                </li>
            </ul>
            <h4 className="annexe__subsubttl">Comment gérer les cookies ?</h4>
            <p className="annexe__text">
            L'enregistrement d'un cookie sur votre terminal est subordonné à votre volonté, que vous pouvez exprimer et/ou modifier à tout moment. A ce titre, vous disposez de différents moyens pour gérer les cookies.Attention, si vous refusez l'enregistrement de cookies nécessaires au fonctionnement de l'Application ou si vous supprimez ceux qui sont enregistrés dans votre terminal, vous ne pourrez plus bénéficier d'un certain nombre de fonctionnalités nécessaires pour naviguer de façon optimale sur la Plateforme et/ou l'Application (par exemple, cookie nous permettant de reconnaitre à des fins de compatibilités techniques le type de navigateur utilisé par votre terminal, ses paramètres de langue et d'affichage ou le pays depuis lequel votre terminal semble connecté à internet). Le cas échéant, nous déclinons toute responsabilité pour les conséquences liées au fonctionnement dégradé de nos services résultant de notre impossibilité d'enregistrer ou de consulter les cookies nécessaires au bon fonctionnement de l'Application.
            </p>
            <h4 className="annexe__subsubttl">
                Comment exercer vos choix depuis votre navigateur web ?
            </h4>
            <p className="annexe__text">
            Vous pouvez configurer votre navigateur internet de manière à ce que des cookies soient enregistrés dans votre terminal, ou au contraire, qu'ils soient rejetés systématiquement ou selon leur émetteur. Vous pouvez également configurer votre navigateur afin que l'acceptation ou le refus des cookies vous soit proposé avant qu'un cookie soit susceptible d'être enregistré dans votre terminal.
            </p>
            <p className="annexe__text">Pour exprimer ou revenir sur vos choix en matière de cookies, reportez-vous au lien correspondant à votre navigateur :</p>
            <ul className="annexe__list">
                <li className="annexe__item">
                    <a href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en" className="annexe__link">Désactiver / Bloquer les cookies sur Google Chrome</a>
                </li>
                <li className="annexe__item">
                    <a href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur" className="annexe__link">Désactiver / Bloquer les cookies sur Mozilla Firefox</a>
                    </li>
                <li className="annexe__item">
                    <a href="https://help.opera.com/en/latest/web-preferences/#cookies" className="annexe__link">Désactiver / Bloquer les cookies sur Opéra</a>
                    </li>
                <li className="annexe__item">
                    <a href="https://www.apple.com/legal/privacy/fr-ww/cookies/" className="annexe__link">Désactiver / Bloquer les cookies sur Safari</a>
                </li>
                <li className="annexe__item">
                    <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" className="annexe__link">Désactiver / Bloquer les cookies sur Internet Explorer</a>
                </li>
            </ul>
            <p className="annexe__text">
            Si vous partagez l'utilisation de votre terminal avec d'autres personnes ou si vous utilisez plusieurs navigateurs depuis le même terminal, nous ne pouvons pas nous assurer de manière certaine que les services et publicités destinés à votre terminal correspondent bien à votre propre utilisation de ce terminal et non à celle d'un autre utilisateur.
            </p>
            <h4 className="annexe__subsubttl">
            Comment exercer vos choix depuis votre smartphone ou tablette ?
            </h4>
            <p className="annexe__text">
            Pour désactiver les cookies depuis un terminal iOS, vous pouvez activer l’option « Suivi publicitaire limité ».
            </p>
            <p className="annexe__text">
            Pour plus d’informations : <a href="https://support.apple.com/fr-fr/HT202074" className="annexe__link">Consulter la page de désactivation des cookies sous iOS.</a>
            </p>
            <p className="annexe__text">Pour désactiver les cookies depuis un terminal Android, vous pouvez activer l’option « Désactiver la diffusion d’annonces par centres d’intérêts ».</p>
            <p className="annexe__text">Pour plus d’informations : <a href="https://support.google.com/googleplay/answer/3405269">Consulter la page de désactivation des cookies sous Android</a>.</p>
        
        </div>
    </section>
    }
}

export default PolConf;