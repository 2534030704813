import React, { Component } from "react";
import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteEvent } from "../../actions/action/events";
import { generateProspection } from "../../actions/action/prospection";
import { getFormatedDateTime } from "../../helpers";

class Event extends Component {
    state = {
        paramsOpen: false,
        alert: {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.deleteEvent.alert !== undefined &&
            Object.keys(this.props.deleteEvent.alert).length > 0 &&
            this.props.deleteEvent.alert !== this.state.alert
        ) {
            this.setState({alert: this.props.deleteEvent.alert});
            alert(this.props.deleteEvent.alert.content);
        }
        if (
            this.props.prospection.alert !== undefined &&
            Object.keys(this.props.prospection.alert).length > 0 &&
            this.props.prospection.alert !== this.state.alert
        ) {
            this.setState({alert: this.props.prospection.alert});
            alert(this.props.prospection.alert.content);
        }
    }

    handleDeleteEvent(id) {
        if (window.confirm("Souhaitez-vous supprimer définitivement cet évènement ?")) {
            this.props.deleteEvent({id: id});
        }
    }

    render() {
        return <article className={`event ${new Date(this.props.event.eventAt.date) < new Date() ? 'event--passed' : ''}`}>
            <div className="event__left">
                <span className="event__badge background">{this.props.event.type}</span>
                <h2 className="event__ttl">{this.props.event.title}</h2>
                <span className="event__date">
                    {
                        this.props.event.eventAt != null &&
                        <>
                            Le <strong>{getFormatedDateTime(this.props.event.eventAt.date)} </strong>
                        </>
                    }
                    {
                        this.props.event.place != null &&
                        <>
                            à <strong>{this.props.event.place}</strong>
                        </>
                    }
                </span>
            </div>

            <div className="event__right">
                {
                    this.props.event.isGrade ? 
                        <button className="event__btnparams" onClick={() => this.setState({paramsOpen: !this.state.paramsOpen})}>
                            <IcomoonReact iconSet={iconSet} icon="dots-horizontal-triple" />
                        </button> :
                        ""
                }
            </div>
            {
                this.props.event.isGrade ? 
                    <nav className={`event__params background ${this.state.paramsOpen && 'event__params--open'}`}>
                        {
                            this.props.event.hasProspection ?
                                <Link to={`/prospection/${this.props.event.id}`} className="event__btn">
                                    <IcomoonReact iconSet={iconSet} icon="map" />
                                    Prospecter
                                </Link> :
                                <button onClick={() => this.props.generateProspection({idEvent: this.props.event.id })} className="event__btn">
                                    <IcomoonReact iconSet={iconSet} icon="map" />
                                    Lancer la prospection
                                </button>
                        }
                        <Link to={`/editer-evenement/${this.props.event.id}`} className="event__btn">
                            <IcomoonReact iconSet={iconSet} icon="pencil" />
                            Modifier
                        </Link>
                        <button onClick={() => this.handleDeleteEvent(this.props.event.id)} className="event__btn">
                            <IcomoonReact iconSet={iconSet} icon="bin" />
                            Supprimer
                        </button>
                    </nav> :
                    ""
            }
            <nav className="event__bottom">
                <Link to={`/evenement/${this.props.event.id}`} className="event__btnbottom">
                    <IcomoonReact iconSet={iconSet} icon="eye" />
                </Link>
            </nav>
        </article>
    }
}

const mapStateToProps = state => {
    return {
        deleteEvent: state.deleteEvent,
        prospection: state.generateProspection
    }
}

const mapDispatchToProps = {
    deleteEvent,
    generateProspection
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);