import React, { Component } from "react";
import { connect } from "react-redux";
import { getProspections, editProspection } from "../../actions/action/prospection";
import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { Link } from "react-router-dom";
import f2r from "../../assets/images/map/f2r.png";
import bassee from "../../assets/images/map/bassee.png";
import faugerit from "../../assets/images/map/faugerit.png";
import pont from "../../assets/images/map/pont.png";
import Map from "../../components/Map";
import BarPercent from "../../components/BarPercent";
import { getFormatedDateTime } from "../../helpers";
import AlertBox from "../../components/AlertBox";

class Prospection extends Component {
    state = {
        step: 1,
        quarter: null,
        paths: [],
        quartersF2r: [],
        id: null
    }

    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
        const pathname = window.location.pathname.split("/");
        const id = pathname[pathname.length - 1];
        this.props.getProspections({ idEvent: id });
        const letters = ["a", "b", "c", "d", "e"];
        const tempQuartersF2r = [];

        for(let i = 1; i<=6; i++) {
            for (let j = 0; j<5; j++) {
                tempQuartersF2r.push("f2r-" + i + letters[j]);
            }
        }
        this.setState({id: id});
        this.setState({ quartersF2r: tempQuartersF2r });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.quarter != this.state.quarter) {
            let pathsTemp = [];

            this.props.prospections?.prospections.forEach((prospec, index) => {
                if (prospec.street.quarter1 == this.state.quarter) {
                    pathsTemp.push({
                        state: prospec.state,
                        path: prospec.street.path1,
                        street: prospec.street.name,
                        prospec: {
                            id: prospec.id,
                            state: prospec.state,
                            prospectedBy: prospec.prospectedBy,
                            prospectedAt: prospec.prospectedAt
                        }
                    });
                }
                if (prospec.street.quarter2 == this.state.quarter) {
                    pathsTemp.push({
                        state: prospec.state,
                        path: prospec.street.path2,
                        street: prospec.street.name,
                        prospec: {
                            id: prospec.id,
                            state: prospec.state,
                            prospectedBy: prospec.prospectedBy,
                            prospectedAt: prospec.prospectedAt
                        }
                    });
                }
                if (prospec.street.quarter3 == this.state.quarter) {
                    pathsTemp.push({
                        state: prospec.state,
                        path: prospec.street.path3,
                        street: prospec.street.name,
                        prospec: {
                            id: prospec.id,
                            state: prospec.state,
                            prospectedBy: prospec.prospectedBy,
                            prospectedAt: prospec.prospectedAt
                        }
                    });
                }
                if (prospec.street.quarter4 == this.state.quarter) {
                    pathsTemp.push({
                        state: prospec.state,
                        path: prospec.street.path4,
                        street: prospec.street.name,
                        prospec: {
                            id: prospec.id,
                            state: prospec.state,
                            prospectedBy: prospec.prospectedBy,
                            prospectedAt: prospec.prospectedAt
                        }
                    });
                }
                if (prospec.street.quarter5 == this.state.quarter) {
                    pathsTemp.push({
                        state: prospec.state,
                        path: prospec.street.path5,
                        street: prospec.street.name,
                        prospec: {
                            id: prospec.id,
                            state: prospec.state,
                            prospectedBy: prospec.prospectedBy,
                            prospectedAt: prospec.prospectedAt
                        }
                    });
                }
            });

            this.setState({paths: pathsTemp});
        }

        if (
            this.props.edition.alert !== undefined &&
            Object.keys(this.props.edition.alert).length > 0 &&
            this.props.edition.alert !== this.state.alert
        ) {
            if(this.props.edition.alert.title != "Données obtenues") {
                alert(this.props.edition.alert.content);
            }
            this.setState({alert: this.props.edition.alert});

            if (this.props.edition.alert.type == "success") {
                this.props.getProspections({ idEvent: this.state.id});
            }
        }
    }

    handleStepTwo({ place }) {
        if (place == "bassee" || place == "faugerit" || place == "pont") {
            this.setState({ step: 3 });
            this.setState({ quarter: place });
        } else {
            this.setState({ step: 2 });
        }
    }

    handleStepThree({ place }) {
        this.setState({ step: 3 });
        this.setState({ quarter: place });
    }

    render() {
        return <section className="prospections">
            <header className="prospections__header">
                <h1 className="prospections__ttl">{this.props.prospections?.event?.title}</h1>
                <Link to="/prospections" className="prospections__back">
                    <IcomoonReact iconSet={iconSet} icon="arrow-left2" />
                </Link>
            </header>
            {
                this.props.prospections != null &&
                this.state.step == 1 ?
                    <div className="prospections__choice">
                        <AlertBox type="info" title="Information" content="Veuillez choisir un lieu de prospection" />
                        <button onClick={() => this.handleStepTwo({ place: "f2r" })} className="prospections__choose">
                            <img src={f2r} className="prospections__chooseimg" alt="Carte Frontenay Rohan Rohan" />
                            <h4 className="prospections__subsubttl">F2R</h4>
                            <BarPercent
                                percent={
                                    this.props.prospections?.totalQuarter.find(item => item.quarter == "f2r")?.percent
                                }
                            />
                        </button>
                        <button onClick={() => this.handleStepTwo({ place: "bassee" })} className="prospections__choose">
                            <img src={bassee} className="prospections__chooseimg" alt="Carte Bassée" />
                            <h4 className="prospections__subsubttl">Bassée</h4>
                            <BarPercent
                                percent={
                                    this.props.prospections?.totalQuarter.find(item => item.quarter == "bassee")?.percent
                                }
                            />
                        </button>
                        <button  onClick={() => this.handleStepTwo({ place: "faugerit" })} className="prospections__choose">
                            <img src={faugerit} className="prospections__chooseimg" alt="Carte Faugerit" />
                            <h4 className="prospections__subsubttl">Faugerit</h4>
                            <BarPercent
                                percent={
                                    this.props.prospections?.totalQuarter.find(item => item.quarter == "faugerit")?.percent
                                }
                            />
                        </button>
                        <button onClick={() => this.handleStepTwo({ place: "pont" })} className="prospections__choose">
                            <img src={pont} className="prospections__chooseimg" alt="Carte Pont" />
                            <h4 className="prospections__subsubttl">Pont</h4>
                            <BarPercent
                                percent={
                                    this.props.prospections?.totalQuarter.find(item => item.quarter == "pont")?.percent
                                }
                            />
                        </button>
                    </div> :
                    (this.state.step == 2 ?
                        <div className="prospections__f2r">
                            <AlertBox type="info" title="Information" content="Veuillez choisir un quartier" />
                            <div className="prospections__f2rchoice">
                                <img src={f2r} alt="Carte Frontenay Rohan Rohan" className="prospections__f2rimg" />
                                <div className="prospections__choicequarter">
                                    {this.state.quartersF2r.map((quarter, index) => <button
                                        key={index} className="prospections__btnquarter"
                                        onClick={() => this.handleStepThree({ place: quarter})}
                                    >
                                        {quarter}
                                        <BarPercent
                                            percent={
                                                this.props.prospections?.totalQuarter.find(item => item.quarter == quarter)?.percent
                                            }
                                        />
                                    </button>)}
                                </div>
                            </div>
                        </div> :
                        (this.state.step == 3) ?
                        <div className="prospections__quarter">
                            <Map map={this.state.quarter} paths={this.state.paths} />
                        </div> : "")

            }
            <AlertBox type="info" title="Information" content="Choisissez une action pour modifier le statut d'une rue" />
            {/** Tableau de prospections */}
            <table className="prospections__table">
                <thead className="prospections__thead">
                    <tr className="prospections__tr">
                        <th className="prospections__th">
                            Rue
                        </th>
                        <th className="prospections__th">
                            Quartier(s)
                        </th>
                        <th className="prospections__th">
                            Par
                        </th>
                        <th className="prospections__th">
                            Etat
                        </th>
                        <th className="prospections__th">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="prospections__tbody">
                    {
                        this.props.prospections?.prospections.filter(prospec => {
                            if (this.state.step == 1) {
                                return true;
                            }
                            if (this.state.step == 2) {
                                if (
                                    prospec.street.quarter1 != "faugerit" &&
                                    prospec.street.quarter1 != "bassee" &&
                                    prospec.street.quarter1 != "pont"
                                ) {
                                    return true;
                                }
                            }
                            if (this.state.step == 3) {
                                if (
                                    this.state.quarter == prospec.street.quarter1 ||
                                    this.state.quarter == prospec.street.quarter2 ||
                                    this.state.quarter == prospec.street.quarter3 ||
                                    this.state.quarter == prospec.street.quarter4 ||
                                    this.state.quarter == prospec.street.quarter5
                                ) {
                                    return true;
                                }
                            }
                        }).map((prospec, index) => <tr className="prospections__tr" key={index}>
                            <td className={`prospections__td prospections__td--${prospec.state}`}>
                                {prospec.street.name}
                            </td>
                            <td className="prospections__td">
                                {prospec.street.quarter1}
                                {prospec.street.quarter2 != "" ? ", " + prospec.street.quarter2 : ""}
                                {prospec.street.quarter3 != "" ? ", " + prospec.street.quarter3 : ""}
                                {prospec.street.quarter4 != "" ? ", " + prospec.street.quarter4 : ""}
                                {prospec.street.quarter5 != "" ? ", " + prospec.street.quarter5 : ""}
                            </td>
                            <td className="prospections__td">
                                {
                                    prospec.prospectedBy.length > 0 ?
                                        <span className="prospections__by">
                                            {
                                                prospec.prospectedBy.map(item => item.firstname + " " + item.lastname + ", ")}</span> : <span className="nothing">Aucun inscrit</span>
                                }
                            </td>
                            <td className="prospections__td">
                                {
                                    prospec.state == "attributed" ?
                                        "En cours" :
                                        prospec.state == "completed" ?
                                            "Faite" :
                                            "Non attribuée"
                                }
                            </td>
                            <td className="prospections__td">
                                <select onChange={el => this.props.editProspection({ idEvent: prospec.id, select: el.target.value })} className="prospections__select">
                                    <option selected disabled>-- Choisir une action --</option>
                                    <option value="loading">Cette rue est en cours</option>
                                    <option value="completed">Cette rue est faite</option>
                                    {
                                        prospec.state != "completed" &&
                                        prospec.prospectedBy.length > 0 &&
                                        prospec.prospectedBy.filter(item => item.id == localStorage['user-id']).length > 0 == localStorage['user-id'] && <option value="unscribe">Je ne m'occupe plus de cette rue</option>
                                    }
                                </select>
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        prospections: state.getProspections,
        edition: state.editProspection
    }
}

const mapDispatchToProps = {
    getProspections,
    editProspection
}

export default connect(mapStateToProps, mapDispatchToProps)(Prospection);