import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getFormatedDateTime } from "../../helpers";
import BarPercent from "../BarPercent";

class Prospection extends Component {
    render() {
        return <article className={`prospection ${new Date(this.props.event.eventAt.date) < new Date() ? 'prospection--passed' : ''}`}>
            <div className="prospection__left">
                <h2 className="prospection__ttl">{this.props.event.title}</h2>
                <span className="prospection__date">
                    {
                        this.props.event.eventAt != null &&
                        <>
                            Le <strong>{getFormatedDateTime(this.props.event.eventAt.date)} </strong>
                        </>
                    }
                </span>
                <BarPercent percent={this.props.event.completion} />
            </div>
            <nav className="prospection__bottom">
                <Link to={`/prospection/${this.props.event.id}`} className="prospection__btnbottom">
                    <IcomoonReact iconSet={iconSet} icon="map" />
                    Prospecter
                </Link>
            </nav>
        </article>
    }
}

export default Prospection;