import axios from "axios";
import { config, getDomain } from "../../helpers";
import { ADD_EVENT, DEL_EVENT, EDIT_EVENT, GET_EVENT, GET_EVENTS } from "../action-types";

// Obtenir les évènements
export function getEvents() {
    return function (dispatch) {
        axios.post(`${getDomain}/api/events`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials']
        }, config)
            .then(res => {
                console.log(res)
                let alert = {};
                let events = [];

                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Évènements reçus', content: "Les évènements ont bien été reçus"};
                    events = res.data.events;
                } else {
                    alert = {type: 'error', title: 'Aucun évènement', content: "Aucun évènement n'a été créé pour le moment."};
                }

                dispatch({
                    type: GET_EVENTS,
                    payload: {
                        alert: alert,
                        events: events
                    }
                })
            })
            .catch(error => console.log(error))
    }
}


// Détails d'un évènement
export function getEvent({ id }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/event/${id}`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials']
        }, config)
            .then(res => {
                let alert = {};
                let event = {};

                if (res.data.status == "success") {
                    alert = {
                        type: 'success',
                        title: 'Évènement reçu',
                        content: "L'évènement a bien été reçu"
                    }
                    event = res.data.event;
                } else {
                    alert = {
                        type: 'error',
                        title: 'Erreur survenue',
                        content: 'Une erreur est survenue, veuillez contacter Matthew'
                    }
                }

                dispatch({
                    type: GET_EVENT,
                    payload: {
                        alert: alert,
                        event: event
                    }
                })
            })
    }
}

// Ajouter un évènement
export function addEvent({ form }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/events/add`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "event-title": form.title,
            "event-type": form.type,
            "event-description": form.description,
            "event-place": form.place,
            "event-at": form.at,
        }, config)
            .then(res => {
                let alert = {};

                if (res.data.status == "success") {
                    alert = {
                        type: 'success',
                        title: 'Évènements créé', 
                        content: "L'évènement a bien été créé"
                    };
                } else {
                    switch (res.data.type) {
                        case 'empty':
                            alert = {
                                type: 'error',
                                title: 'Erreur formulaire', 
                                content: "Veuillez remplir tous les champs obligatoires"
                            }
                            break;
                        case 'not-right':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: ADD_EVENT,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}

// Edition d'un évènement
export function editEvent({ form, id }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/events/edit/${id}`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "event-title": form.title,
            "event-type": form.type,
            "event-description": form.description,
            "event-place": form.place,
            "event-at": form.at,
        }, config)
            .then(res => {
                let alert = {};

                if (res.data.status == "success") {
                    alert = {
                        type: 'success',
                        title: 'Évènements édité', 
                        content: "L'évènement a bien été modifié"
                    };
                } else {
                    switch (res.data.type) {
                        case 'empty':
                            alert = {
                                type: 'error',
                                title: 'Erreur formulaire', 
                                content: "Veuillez remplir tous les champs obligatoires"
                            }
                            break;
                        case 'not-right':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: EDIT_EVENT,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}

// Supprimer un évènement
export function deleteEvent({ id }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/events/delete/${id}`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials']
        }, config)
            .then(res => {
                let alert = {};
                
                if (res.data.status == "success") {
                    alert = {
                        type: 'success',
                        title: 'Évènements supprimé', 
                        content: "L'évènement a bien été supprimé"
                    };
                } else {
                    switch (res.data.type) {
                        case "not-right":
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            };
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: DEL_EVENT,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}