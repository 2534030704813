import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllProspections } from "../../actions/action/prospection";
import { isConnected } from "../../helpers";
import Prospection from "../../components/events/Prospection";

class Prospections extends Component {
    state = {
        alert: {
            type: 'warning',
            title: 'Attente',
            content: 'Recherche des prospections...'
        }
    }

    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
        this.props.getAllProspections();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.events.alert !== undefined &&
            Object.keys(this.props.events.alert).length > 0 &&
            this.props.events.alert !== this.state.alert
        ) {
            if (this.props.events.alert.title != "Evènements obtenus") {
                alert(this.props.events.alert.content);
            }
            this.setState({alert: this.props.events.alert});
        }
    }

    render() {
        return <section className="prospections">
            {isConnected()}
            <header className="prospections__header">
                <h1 className="prospections__ttl">Les prospections</h1>
            </header>
            {
                this.props.events != null &&
                <ul className="prospections__list">
                    {
                        this.props.events.events.map((event, index) => <Prospection event={event} key={index} />)
                    }
                </ul>
            }
        </section>
    }
}

const mapStateToProps = state => {
    return {
        events: state.getAllProspections
    }
}

const mapDispatchToProps = {
    getAllProspections
}

export default connect(mapStateToProps, mapDispatchToProps)(Prospections);