import axios from "axios";
import { config, getDomain } from "../../helpers";
import { ADD_USER, LOG_USER, FORGOT_PASSWORD } from "../action-types";

// Connexion
export function loginCheck({ form }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/signin`, {
            "username": form.email,
            "password": form.password
        }, config)
            .then(response => {
                let alert = {};
                if (response.data.status) {
                    if (response.data.status != "success") {
                        alert = {type: 'error', title: 'Erreur', content: "Vos identifiants sont incorrects."};
                    } else {
                        localStorage.setItem('credentials', response.data.datas.credentials);
                        localStorage.setItem('user-id', response.data.datas.user.id);
                        localStorage.setItem('user-lastname', response.data.datas.user.lastname);
                        localStorage.setItem('user-firstname', response.data.datas.user.firstname);
                        localStorage.setItem('user-avatar', response.data.datas.user.avatar);
                        localStorage.setItem('user-role', response.data.datas.user.role);

                        window.location.reload();
                    }
                } else {
                    alert = {type: 'error', title: 'Erreur', content: "Vos identifiants sont incorrects."};
                }

                
                dispatch({
                    type: LOG_USER,
                    payload: {
                        disabled: false,
                        alert: alert
                    }
                });
            })
            .catch(error => {
                console.log(error);
                let alert = {};
                if (error.response.status == 401) {
                    alert = {type: 'error', title: 'Erreur', content: "Vos identifiants sont incorrects."};
                } else {
                    alert = {type: 'error', title: 'Erreur', content: "Une erreur est survenue, veuillez contacter Matthew."};
                }

                dispatch({
                    type: LOG_USER,
                    payload: {
                        disabled: false,
                        alert: alert
                    }
                });
            });
    }
}

// Ajout user
export function addUser({ form }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/signup`, {
            "lastname": form.lastname,
            "firstname": form.firstname,
            "email": form.email,
            "password": form.password,
            "confirm-password": form.confirmPassword,
            "rgpd": form.rgpd,
            "telephone": form.telephone,
            "notif-annonce-sms": form.notifAnnonceSms,
            "notif-annonce-email": form.notifAnnonceEmail,
            "notif-event-sms": form.notifEventSms,
            "notif-event-email": form.notifEventEmail,
        }, config)
            .then(res => {
                let alert = {};
                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Création de compte', content: "Votre compte a bien été créé !"};
                } else {
                    switch (res.data.type) {
                        case 'size-password':
                            alert = {type: 'error', title: 'Erreur mot de passe', content: "Veuillez utiliser un mot de passe supérieur ou égale à 8 caractères"};
                            break;
                        case 'password-not-match':
                            alert = {type: 'error', title: 'Erreur mot de passe', content: "Vos mot de passe ne correspondent pas"};
                            break;
                        case 'mail-exist':
                            alert = {type: 'error', title: 'Erreur adresse e-mail', content: 'Cette adresse e-mail est déjà prise'};
                            break;
                        case 'empty':
                            alert = {type: 'error', title: 'Erreur formulaire', content: 'Veuillez remplir tout les champs'};
                            break;
                    }
                }
                dispatch({
                    type: ADD_USER,
                    payload: {
                        disabled: false,
                        alert: alert
                    }
                })
            })
    }
}

// Mot de passe oublié - 1ère étape
export function forgotPasswordFirstStep({ form }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/forgot-password/email`, {
            "email": form.email
        }, config)
            .then(res => {
                let alert = {};
                let step = 1;
                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Code envoyé', content: "Un code vous a été envoyé par mail"};
                    step = 2
                } else {
                    switch (res.data.type) {
                        case 'user-inexist':
                            alert = {type: 'error', title: 'Erreur adresse e-mail', content: 'Cette adresse e-mail n\'est associée à aucun compte.'};
                            break;
                        case 'empty':
                            alert = {type: 'error', title: 'Erreur formulaire', content: 'Veuillez inscrire une adresse e-mail.'};
                            break;
                    }
                }

                dispatch({
                    type: FORGOT_PASSWORD,
                    payload: {
                        disabled: false,
                        alert: alert,
                        step: step
                    }
                })
            })
    }
}

// Mot de passe oublié - 2nd étape
export function forgotPasswordSecondStep({ form, formPrev }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/forgot-password/edit-password`, {
            "code": form.code,
            "password": form.password,
            "confirm-password": form.confirmPassword,
            "email": formPrev.email
        }, config)
            .then(res => {
                let alert = {};
                let disabled = true;
                let step = 2;
                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Mot de passe modifié', content: "Votre mot de passe a bien été modifié. Vous pouvez vous connecter à présent."};
                } else {
                    disabled = false;
                    switch (res.data.type) {
                        case 'size-password':
                            alert = {type: 'error', title: 'Erreur mot de passe', content: "Veuillez utiliser un mot de passe supérieur ou égale à 8 caractères"};
                            break;
                        case 'password-not-match':
                            alert = {type: 'error', title: 'Erreur mot de passe', content: "Vos mot de passe ne correspondent pas"};
                            break;
                        case 'code-not-match':
                            alert = {type: 'error', title: 'Erreur code', content: "Ce code ne correspond pas au code envoyé par mail"};
                            break;
                        case 'forgot-inexist':
                            alert = {type: 'error', title: 'Erreur survenue', content: "Veuillez recommencer la procédure du début ou contactez Matthew"};
                            break;
                        case 'user-inexist':
                            alert = {type: 'error', title: 'Erreur formulaire', content: "Cet utilisateur n'existe pas"};
                            break;
                        case 'empty':
                            alert = {type: 'error', title: 'Erreur formulaire', content: "Veuillez remplir tout les champs"};
                            break;
                    }
                }

                dispatch({
                    type: FORGOT_PASSWORD,
                    payload: {
                        disabled: disabled,
                        alert: alert,
                        step: step
                    }
                })
            })
    }
}