import React, { Component } from "react";
import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { Link } from "react-router-dom";
import { editStreet, getStreet } from "../../actions/action/street";
import { connect } from "react-redux";
import AlertBox from "../../components/AlertBox";
import { isConnected } from "../../helpers";

class StreetEdit extends Component {
    state = {
        form: {
            name: null,
            quarter1: null,
            quarter2: null,
            quarter3: null,
            quarter4: null,
            quarter5: null,
            path1: null,
            path2: null,
            path3: null,
            path4: null,
            path5: null,
        },
        disabled: false,
        alert: null,
        svg: null,
        getStreet: false,
    };

    componentDidMount() {
        const pathname = window.location.pathname.split("/");
        const id = pathname[pathname.length - 1];
        this.setState({id: id});
        this.props.getStreet({id: id});
        this.props.setPageActive(this.props.currentPage);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.edit.alert !== undefined &&
            Object.keys(this.props.edit.alert).length > 0 &&
            this.props.edit.alert !== this.state.alert
        ) {
            alert(this.props.edit.alert.content);
            this.setState({alert: this.props.edit.alert});
        }

        if (
            this.props.edit.disabled !== undefined &&
            this.props.edit.disabled !== this.state.disabled
        ) {
            this.setState({disabled: this.props.edit.disabled});
        }
        
        if (
            this.state.getStreet == false &&
            this.props.street !== undefined &&
            Object.keys(this.props.street.street).length > 0
        ) {
            this.setState({ getStreet: true });
            this.setState(prevState => ({
                form: {
                        name: this.props.street.street.name,
                        quarter1: this.props.street.street.quarter1,
                        quarter2: this.props.street.street.quarter2,
                        quarter3: this.props.street.street.quarter3,
                        quarter4: this.props.street.street.quarter4,
                        quarter5: this.props.street.street.quarter5,
                        path1: this.props.street.street.path1,
                        path2: this.props.street.street.path2,
                        path3: this.props.street.street.path3,
                        path4: this.props.street.street.path4,
                        path5: this.props.street.street.path5,
                    }
            }))
        }
    }

    handleFormChange = (e) => {
        this.setState({ form: {
            ...this.state.form,
            [e.target.name]: e.target.value
        }})
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true });
        this.setState({
            alert: {
                type: 'warning',
                title: 'Attente',
                content: 'Enregistrement des données en cours...'
            }
        });
        this.props.editStreet({ form: this.state.form, id: this.state.id });
    }

    render() {
        return <section className="streets">
            {isConnected()}
            <header className="streets__header">
                <h1 className="streets__ttl">Modification d'une rue</h1>
                <nav className="streets__menu">
                    <Link to="/rues" className="btn btn--secondary">
                        <IcomoonReact iconSet={iconSet} icon="arrow-left2" />
                        Revenir en arrière
                    </Link>
                </nav>
            </header>
            <div className="events__content">
                {this.state.alert && Object.keys(this.state.alert).length > 0 && <AlertBox {...this.state.alert} />}
                <p className="form__help">* champs obligatoires</p>
                <form className="form">
                    {/** Nom */}
                    <div className="form__formgrp">
                        <label htmlFor="name" className="form__label">Nom *</label>
                        <input
                            type="text"
                            placeholder="Nom"
                            id="name"
                            name="name"
                            className="form__input"
                            value={this.state.form.name}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                            required
                        />
                    </div>
                    {/** Quartier 1 */}
                    <div className="form__formgrp">
                        <label htmlFor="quarter1" className="form__label">Quartier 1</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="quarter1"
                            name="quarter1"
                            className="form__input"
                            value={this.state.form.quarter1}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Quartier 2 */}
                    <div className="form__formgrp">
                        <label htmlFor="quarter2" className="form__label">Quartier 2</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="quarter2"
                            name="quarter2"
                            className="form__input"
                            value={this.state.form.quarter2}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Quartier 3 */}
                    <div className="form__formgrp">
                        <label htmlFor="quarter3" className="form__label">Quartier 3</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="quarter3"
                            name="quarter3"
                            className="form__input"
                            value={this.state.form.quarter3}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Quartier 4 */}
                    <div className="form__formgrp">
                        <label htmlFor="quarter4" className="form__label">Quartier 4</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="quarter4"
                            name="quarter4"
                            className="form__input"
                            value={this.state.form.quarter4}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Quartier 5 */}
                    <div className="form__formgrp">
                        <label htmlFor="quarter5" className="form__label">Quartier 5</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="quarter5"
                            name="quarter5"
                            className="form__input"
                            value={this.state.form.quarter5}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Path 1 */}
                    <div className="form__formgrp">
                        <label htmlFor="path1" className="form__label">Path 1</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="path1"
                            name="path1"
                            className="form__input"
                            value={this.state.form.path1}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Path 2 */}
                    <div className="form__formgrp">
                        <label htmlFor="path2" className="form__label">Path 2</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="path2"
                            name="path2"
                            className="form__input"
                            value={this.state.form.path2}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Path 3 */}
                    <div className="form__formgrp">
                        <label htmlFor="path3" className="form__label">Path 3</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="path3"
                            name="path3"
                            className="form__input"
                            value={this.state.form.path3}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Path 4 */}
                    <div className="form__formgrp">
                        <label htmlFor="path4" className="form__label">Path 4</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="path4"
                            name="path4"
                            className="form__input"
                            value={this.state.form.path4}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Path 5 */}
                    <div className="form__formgrp">
                        <label htmlFor="path5" className="form__label">Path 5</label>
                        <input
                            type="text"
                            placeholder="Quartier"
                            id="path5"
                            name="path5"
                            className="form__input"
                            value={this.state.form.path5}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    <div className="form__listBtn form__listBtn--row">
                        <Link to="/rues" className="btn btn--secondary">
                            <IcomoonReact iconSet={iconSet} icon="cross" />
                            Annuler
                        </Link>
                        <button type="submit" onClick={this.handleSubmit} className="btn background">
                            <IcomoonReact iconSet={iconSet} icon="checkmark" />
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        edit: state.editStreet,
        street: state.getStreet
    }
}

const mapDispatchToProps = {
    editStreet,
    getStreet
}

export default connect(mapStateToProps, mapDispatchToProps)(StreetEdit);