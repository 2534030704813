import React, { Component, useContext, useEffect, useState } from "react";
import { isConnected } from "../helpers";
import { AuthContext } from "../App";
import SignUp from "../components/registration/SignUp";
import SignIn from "../components/registration/SignIn";
import ForgetPassword from "../components/registration/ForgetPassword";
import { Navigate } from "react-router-dom";

const Home = () => {
    const [state, action] = useContext(AuthContext);
    const {
        login
    } = action;
    
    const [step, setStep] = useState("co");

    return (
        <>
            {
                isConnected() == true ?
                    <Navigate to={'/dashboard'} state={{from: window.location.pathname}} replace={true} /> :
                    <div className="background registrationDiv">
                        <SignIn stepRegistr={step} setStep={setStep} />
                        <SignUp stepRegistr={step} setStep={setStep} />
                        <ForgetPassword stepRegistr={step} setStep={setStep} />
                    </div>
            }
        </>
    )
}

export default Home;