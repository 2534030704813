import React, { Component } from "react";
import { Link } from "react-router-dom";
import iconSet from "../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";

class Contact extends Component {
    render() {
        return <section className="contact">
            <h2 className="contact__ttl">Contacts</h2>
            <h3 className="contact__subttl">Un problème sur le site ? Des idées concernant le site ? ... Contacez Matthew</h3>
            <ul className="contact__list">
                <li className="contact__item">
                    <Link
                        className="contact__link"
                        to="#"
                        onClick={(e) => {
                            window.location.href = "mailto:m.guimbretiere937@gmail.com";
                            e.preventDefault();
                        }}
                    >
                        <IcomoonReact iconSet={iconSet} icon="envelop" />
                        m.guimbretiere937@gmail.com
                    </Link>
                </li>
                <li className="contact__item">
                    <Link
                        className="contact__link"
                        to="#"
                        onClick={(e) => {
                            window.location.href = "tel:0626671883";
                            e.preventDefault();
                        }}
                    >
                        <IcomoonReact iconSet={iconSet} icon="phone" />
                        06.26.67.18.83
                    </Link> 
                </li>
            </ul>
        </section>
    }
}

export default Contact;