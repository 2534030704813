import React, { Component } from "react";
import { isConnected } from "../helpers";
import AlertBox from "../components/AlertBox";

class Dashboard extends Component {
    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
    }
    
    render() {
        return <div className="dashboard">
            {isConnected()}
            <h2 className="dashboard__ttl">Bienvenue sur votre espace membre !</h2>
            <h3 className="dashboard__subttl">Vous pouvez naviguez sur le site à l'aide du bouton situé en haut à gauche</h3>
            <AlertBox type="info" title="Information" content="De nouvelles fonctionnalitées arriveront très prochainement !" />
        </div>
    }
}

export default Dashboard