import React, { Component } from "react";
import { connect } from "react-redux";
import { addUser } from "../../actions/action/user";
import AlertBox from "../AlertBox";
import logo from "../../assets/images/logo/logo.png";

class SignUp extends Component {
    state = {
        formState: {
            email: null,
            password: null,
            lastname: null,
            firstname: null,
            telephone: null,
            confirmPassword: null,
            notifAnnonceSms: false,
            notifAnnonceEmail: false,
            notifEventSms: false,
            notifEventEmail: false,
            rgpd: false
        },
        disabled: false,
        alert: null
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.alert !== undefined &&
            Object.keys(this.props.alert).length > 0 &&
            this.props.alert !== this.state.alert
        ) {
            alert(this.props.alert.content);
            this.setState({alert: this.props.alert});
        }

        if (
            this.props.disabled !== undefined &&
            this.props.disabled !== this.state.disabled
        ) {
            this.setState({disabled: this.props.disabled});
        }
    }

    handleFormChange = (e) => {
        if (
            e.target.name === 'notifAnnonceSms' ||
            e.target.name === 'notifAnnonceEmail' ||
            e.target.name === 'notifEventSms' ||
            e.target.name === 'notifEventEmail' ||
            e.target.name === 'rgpd'
        ) {
            this.setState({ formState: {
                ...this.state.formState,
                [e.target.name]: e.target.checked
            }})
        } else {
            this.setState({ formState: {
                ...this.state.formState,
                [e.target.name]: e.target.value
            }})
        }
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true });
        this.setState({ alert: {type: 'warning', title: 'Attente', content: 'Analyse et enregistrement de vos données en cours...'}})
        this.props.addUser({ form: this.state.formState });
    }

    render() {
        return <section
            className={`registration ${
                this.props.stepRegistr == "forgot" ?
                    'registration--rightdouble' :
                    this.props.stepRegistr == "co" &&
                        'registration--right'
            }`}
        >
            <img src={logo} alt="Logo de F2R en fête" className="registration__img" />
            {this.state.alert && Object.keys(this.state.alert).length > 0 && <AlertBox {...this.state.alert} />}
            <h2 className="registration__ttl">Inscrivez-vous</h2>
            <p className="registration__help">Nb: Seul les adhérants de F2R peuvent s'inscrire sur ce site</p>
            <form className="form">
                <p className="form__help">* champs obligatoires</p>
                {/** Nom de famille */}
                <div className="form__formgrp">
                    <label htmlFor="lastname" className="form__label">Nom *</label>
                    <input
                        type="text"
                        placeholder="Nom de famille"
                        id="lastname"
                        name="lastname"
                        className="form__input"
                        value={this.state.formState.lastname}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Prénom */}
                <div className="form__formgrp">
                    <label htmlFor="firstname" className="form__label">Prénom *</label>
                    <input
                        type="text"
                        placeholder="Prénom"
                        id="firstname"
                        name="firstname"
                        className="form__input"
                        value={this.state.formState.firstname}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Email */}
                <div className="form__formgrp">
                    <label htmlFor="email" className="form__label">Adresse E-mail *</label>
                    <input
                        type="mail"
                        placeholder="exemple@mail.com"
                        id="email"
                        name="email"
                        className="form__input"
                        value={this.state.formState.email}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Mot de passe */}
                <div className="form__formgrp">
                    <label htmlFor="password" className="form__label">Mot de passe *</label>
                    <input
                        type="password"
                        placeholder="Mot de passe"
                        id="password"
                        name="password"
                        className="form__input"
                        value={this.state.formState.password}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Confirmation du mot de passe */}
                <div className="form__formgrp">
                    <label htmlFor="confirmPassword" className="form__label">Confirmation mot de passe *</label>
                    <input
                        type="password"
                        placeholder="Confirmation du mot de passe"
                        id="confirmPassword"
                        name="confirmPassword"
                        className="form__input"
                        value={this.state.formState.confirmPassword}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Téléphone */}
                <div className="form__formgrp">
                    <label htmlFor="telephone" className="form__label">Téléphone</label>
                    <input
                        type="tel"
                        placeholder="00.00.00.00.00"
                        id="telephone"
                        name="telephone"
                        className="form__input"
                        value={this.state.formState.telephone}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Notifs - Annonce - SMS */}
                <div className="form__formgrp form__formgrp--notifs">
                    <label className="form__label">Je souhaite être notifié.e...</label>
                    <div className="form__formgrp--notif">
                        <input
                            type="checkbox"
                            id="notifAnnonceSms"
                            name="notifAnnonceSms"
                            className="form__checkbox"
                            value={this.state.formState.notifAnnonceSms}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                        <label htmlFor="notifAnnonceSms" className="form__label">par <strong>SMS</strong> pour les prochaines <strong>annonces</strong></label>
                    </div>
                    <div className="form__formgrp--notif">
                        <input
                            type="checkbox"
                            id="notifAnnonceEmail"
                            name="notifAnnonceEmail"
                            className="form__checkbox"
                            value={this.state.formState.notifAnnonceEmail}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                        <label htmlFor="notifAnnonceEmail" className="form__label">par <strong>Email</strong> pour les prochaines <strong>annonces</strong></label>
                    </div>
                    <div className="form__formgrp--notif">
                        <input
                            type="checkbox"
                            id="notifEventSms"
                            name="notifEventSms"
                            className="form__checkbox"
                            value={this.state.formState.notifEventSms}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                        <label htmlFor="notifEventSms" className="form__label">par <strong>SMS</strong> pour les prochains <strong>évènements</strong></label>
                    </div>
                    <div className="form__formgrp--notif">
                        <input
                            type="checkbox"
                            id="notifEventEmail"
                            name="notifEventEmail"
                            className="form__checkbox"
                            value={this.state.formState.notifEventEmail}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                        <label htmlFor="notifEventEmail" className="form__label">par <strong>Email</strong> pour les prochains <strong>évènements</strong></label>
                    </div>
                </div>

                {/** RGPD */}
                <div className="form__formgrp--notif">
                    <input
                        type="checkbox"
                        id="rgpd"
                        name="rgpd"
                        className="form__checkbox"
                        value={this.state.formState.rgpd}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                    <label htmlFor="rgpd" className="form__label">J'accepte la politique de confidentialité *</label>
                </div>

                <button type="submit" className="btn background" onClick={this.handleSubmit}>Je m'inscris !</button>
            </form>
            <div className="form__listBtn form__listBtn--registration">
                <button className="btn btn--secondary" onClick={() => this.props.setStep("co")}>Vous avez déjà un compte ? Connectez-vous</button>
                <button className="btnlink" onClick={() => this.props.setStep("forgot")}>Problème de connexion ?</button>
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return state.addUser
}

const mapDispatchToProps = {
    addUser
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);