import {
    ADD_EVENT,
    DEL_EVENT,
    EDIT_EVENT,
    GET_EVENT,
    GET_EVENTS,
} from "../actions/action-types";

// Get events
export function GetEventsReducer(state = {
    alert: {},
    events: []
}, action) {
    switch (action.type) {
        case GET_EVENTS:
            return action.payload;
        default:
            return state
    }
}

// Get event
export function GetEventReducer(state = {
    alert: {},
    event: {}
}, action) {
    switch (action.type) {
        case GET_EVENT:
            return action.payload;
        default:
            return state;
    }
}

// Add event
export function AddEventReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case ADD_EVENT:
            return action.payload;
        default:
            return state;
    }
}

// Edit event
export function EditEventReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case EDIT_EVENT:
            return action.payload;
        default:
            return state;
    }
}

// Delete event
export function DeleteEventReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case DEL_EVENT:
            return action.payload;
        default:
            return state;
    }
}