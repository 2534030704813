import React, { Component } from 'react';
import { isConnected } from '../helpers';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Tchat from '../components/Tchat';

class Layout extends Component {
    render() {
        return (
            <>
                <div className='container'>
                    {
                        isConnected() == true ? 
                        <Header pageActu={this.props.pageActu} /> : ""
                    }
                    <div className='content'>
                        {this.props.children}
                    </div>
                </div>
                <Tchat />
            </>
        )
    }
}

export default Layout;