import React, { Component } from "react";
import iconSet from "../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { Link, Navigate } from "react-router-dom";
import { isConnected } from "../helpers";

class Header extends Component {
    state = {
        open: false,
    }

    handleLogout() {
        localStorage.clear();
        this.setState({ isCo: false });
        this.setState({ open: false });
        window.location.reload();
    }

    handleAllDelete() {
        sessionStorage.clear()

        localStorage.clear()

        caches.keys().then(keys => {
        keys.forEach(key => caches.delete(key))
        })

        indexedDB.databases().then(dbs => {
        dbs.forEach(db => indexedDB.deleteDatabase(db.name))
        })

        document.cookie = document.cookie.split(';').reduce((newCookie1, keyVal) => {
        var pair = keyVal.trim().split('=')
        if (pair[0]) {
            if (pair[0] !== 'path' && pair[0] !== 'expires') {
            newCookie1 += pair[0] + '=;'
            }
        }
        return newCookie1
        }, 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path:/;')

        window.location.reload();
    }

    render() {
        return <>
            {
            localStorage && localStorage['credentials'] && localStorage['user-id'] &&
            <header className="header">
            <button className="header__menubtn" onClick={() => this.setState({open: true})}>
                <IcomoonReact iconSet={iconSet} icon="menu" />
            </button>
            <nav className={`header__menu ${this.state.open ? "header__menu--active" : ""} background`}>
                <button className="header__btnclose" onClick={() => this.setState({open: false})}>
                    <IcomoonReact iconSet={iconSet} icon="cross" />
                </button>
                <ul className="header__list">
                    <li className={`header__item ${this.props.pageActu == "dashboard" ? "header__item--active" : ""}`}>
                        <Link onClick={() => this.setState({ open: false })} to="/" className="header__link">
                            <IcomoonReact iconSet={iconSet} icon="home3" />
                            Tableau de bord
                        </Link>
                    </li>
                    <li className={`header__item ${this.props.pageActu == "events" ? "header__item--active" : ""}`}>
                        <Link onClick={() => this.setState({ open: false })} to="/evenements" className="header__link">
                            <IcomoonReact iconSet={iconSet} icon="calendar" />
                            Évènements
                        </Link>
                    </li>
                    <li className={`header__item ${this.props.pageActu == "prospections" ? "header__item--active" : ""}`}>
                        <Link onClick={() => this.setState({ open: false })} to="/prospections" className="header__link">
                            <IcomoonReact iconSet={iconSet} icon="map" />
                            Prospection
                        </Link>
                    </li>
                    <li className={`header__item`}>
                        <button onClick={() => {
                            this.handleLogout()
                        }} className="header__link header__link--red">
                            <IcomoonReact iconSet={iconSet} icon="lock" />
                            Déconnexion
                        </button>
                    </li>
                </ul>
                <ul className="header__list header__list--bottom">
                    <li className="header__item">
                        <Link className="header__link" to="/contact">
                            Contacts
                        </Link>
                    </li>
                    <li className="header__item">
                        <Link className="header__link" to="/mentions-legales">
                            Mentions Légales
                        </Link>
                    </li>
                    <li className="header__item">
                        <Link className="header__link" to="/politiques-de-confidentialite">
                            Politiques de Confidentialité
                        </Link>
                    </li>
                    <li className="header__item">
                        <button className="header__link header__link--red" onClick={() => this.handleAllDelete()}>
                            Remettre à zéro
                        </button>
                    </li>
                </ul>
            </nav>
        </header>
        }</> 
    }
}

export default Header;