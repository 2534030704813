// User
export const LOG_USER = "LOG_USER";
export const ADD_USER = "ADD_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

// Events
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT = "GET_EVENT";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const DEL_EVENT = "DEL_EVENT";

// Streets
export const GET_STREETS = "GET_STREETS";
export const GET_STREET = "GET_STREET";
export const ADD_STREET = "ADD_STREET";
export const EDIT_STREET = "EDIT_STREET";
export const DEL_STREET = "DEL_STREET";

// Prospection
export const GENERATE_PROSPECTION = "GENERATE_PROSPECTION";
export const GET_ALL_PROSPECTIONS = "GET_ALL_PROSPECTIONS";
export const GET_PROSPECTIONS = "GET_PROSPECTIONS";
export const EDIT_PROSPECTION = "EDIT_PROSPECTION";