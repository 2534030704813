import React, { Component } from "react";
import { connect } from "react-redux";
import { getStreet, deleteStreet } from "../../actions/action/street";
import { Link } from "react-router-dom";
import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import AlertBox from "../../components/AlertBox";
import { isConnected } from "../../helpers";
import Map from "../../components/Map";

class Street extends Component {
    state = {
        alert: {
            type: 'warning',
            title: 'Attente',
            content: 'Recherche de la rue...'
        },
        street: {},
        id: null,
        paramsOpen: false
    }

    componentDidMount() {
        const pathname = window.location.pathname.split("/");
        const id = pathname[pathname.length - 1];
        this.props.setPageActive(this.props.currentPage);
        this.props.getStreet({id: id});
        this.setState({id: id});
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.street.alert !== undefined &&
            Object.keys(this.props.street.alert).length > 0 &&
            this.props.street.alert !== this.state.alert
        ) {
            alert(this.props.street.alert.content);
            this.setState({alert: this.props.street.alert});
            this.setState({street: this.props.street.street});
        }
        if (
            this.props.delete.alert !== undefined &&
            Object.keys(this.props.delete.alert).length > 0 &&
            this.props.delete.alert !== this.state.alert
        ) {
            alert(this.props.delete.alert.content);
            this.setState({alert: this.props.delete.alert});
        }
    }

    handleDelete() {
        if (window.confirm("Souhaitez-vous supprimer définitivement cette rue ?")) {
            this.props.deleteStreet({id: this.state.id});
        }
    }

    render() {
        return <section className="streets">
            {isConnected()}
            <header className="streets__header">
                <h1 className="streets__ttl">{this.state.street?.name}</h1>
                <nav className="streets__menu">
                    <Link to="/rues" className="btn btn--secondary">
                        <IcomoonReact iconSet={iconSet} icon="arrow-left2" />
                        Retour aux rues
                    </Link>
                </nav>
                <button className="streets__btnparams" onClick={() => this.setState({paramsOpen: !this.state.paramsOpen})}>
                    <IcomoonReact iconSet={iconSet} icon="dots-horizontal-triple" />
                </button>
            </header>
            <nav className={`streets__params background ${this.state.paramsOpen && 'streets__params--open'}`}>
                <Link to={`/editer-rue/${this.state.id}`} className="streets__btn">
                    <IcomoonReact iconSet={iconSet} icon="pencil" />
                    Modifier
                </Link>
                <button onClick={() => this.handleDelete()} className="events__btn">
                    <IcomoonReact iconSet={iconSet} icon="bin" />
                    Supprimer
                </button>
            </nav>
            {this.state.alert && Object.keys(this.state.alert).length > 0 && this.state.alert.type !== "success" && <AlertBox {...this.state.alert} />}
            {this.state.street?.path1 != null && <h2>{this.state.street?.quarter1}</h2>}
            {
                this.state.street?.path1 != null ?
                    <Map paths={[this.state.street?.path1]} map={this.state.street?.quarter1} /> : ""
            }
            {this.state.street?.path2 != null && <h2>{this.state.street?.quarter2}</h2>}
            {
                this.state.street?.path2 != null ?
                    <Map paths={[this.state.street?.path2]} map={this.state.street?.quarter2} /> : ""
            }
            {this.state.street?.path3 != null && <h2>{this.state.street?.quarter3}</h2>}
            {
                this.state.street?.path3 != null ?
                    <Map paths={[this.state.street?.path3]} map={this.state.street?.quarter3} /> : ""
            }
            {this.state.street?.path4 != null && <h2>{this.state.street?.quarter4}</h2>}
            {
                this.state.street?.path4 != null ?
                    <Map paths={[this.state.street?.path4]} map={this.state.street?.quarter4} /> : ""
            }
            {this.state.street?.path5 != null && <h2>{this.state.street?.quarter5}</h2>}
            {
                this.state.street?.path5 != null ?
                    <Map paths={[this.state.street?.path5]} map={this.state.street?.quarter5} /> : ""
            }
        </section>
    }
}

const mapStateToProps = state => {
    return {
        street: state.getStreet,
        delete: state.deleteStreet
    }
}

const mapDispatchToProps = {
    getStreet,
    deleteStreet
}

export default connect(mapStateToProps, mapDispatchToProps)(Street);