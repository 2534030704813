import React, { Component } from "react";

class BarPercent extends Component {
    state = {
        percent: 0
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({percent: this.props.percent})
        }, 500);
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.setState({percent: this.props.percent})
        }, 500);
    }

    render() {
        return <div className="bar">
            <span className="bar__percent" style={{left: this.state.percent - 3 + "%"}}>{Math.round(this.state.percent)} %</span>
            <div className="bar__fill background" style={{width: this.state.percent + "%"}}></div>
        </div>
    }
}

export default BarPercent;