import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return <footer className="footer">
            <ul className="footer__list">
                <li className="footer__item">
                    <Link className="footer__link" to="/contact">
                        Contacts
                    </Link>
                </li>
                <li className="footer__item">
                    <Link className="footer__link" to="/mentions-legales">
                        Mentions Légales
                    </Link>
                </li>
                <li className="footer__item">
                    <Link className="footer__link" to="/politiques-de-confidentialite">
                        Politiques de Confidentialité
                    </Link>
                </li>
            </ul>
        </footer>
    }
}

export default Footer;