import React, { Component } from "react";
import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import AlertBox from "../AlertBox";
import { connect } from "react-redux";
import { getProspections, editProspection } from "../../actions/action/prospection";
import { getFormatedDateTime } from "../../helpers";

class ModalProspection extends Component {
    state = {
        alert: {}
    }

    componentDidUpdate() {
        const pathname = window.location.pathname.split("/");
        const id = pathname[pathname.length - 1];

        if (
            this.props.edition.alert !== undefined &&
            Object.keys(this.props.edition.alert).length > 0 &&
            this.props.edition.alert !== this.state.alert
        ) {
            this.setState({alert: this.props.edition.alert});
            if(this.props.edition.alert.title != "Données obtenues") {
                alert(this.props.edition.alert.content);
            }
            if (this.props.edition.alert.type == "success") {
                this.props.getProspections({ idEvent: id });
            }
        }
    }
    
    render() {
        return <section className={`modal ${this.props.openModal && "modal--open"}`}>
            <div className="modal__content">
                <header className="modal__header">
                    <h2 className="modal__ttl">{this.props.street}</h2>
                    <span className="modal__state">Etat actuel :
                        {
                            this.props.prospec?.state == "attributed" ?
                                " Attribuée " :
                                this.props.prospec?.state == "completed" ?
                                    " Faite " :
                                    " Non attribuée "
                        }
                        {
                            this.props.prospec?.state == "attributed" &&
                            this.props.prospec?.prospectedBy.length > 0 && <>
                                à {" "}
                                {this.props.prospec?.prospectedBy.map(user => user.firstname + " " + user.lastname + ", ")}
                            </>
                        }
                        {
                            this.props.prospec?.state == "completed" &&
                            this.props.prospec?.prospectedBy.length > 0 && <>
                                par {" "}
                                {this.props.prospec?.prospectedBy.map(user => user.firstname + " " + user.lastname + ", ")}
                            </>
                        }
                        {
                            this.props.prospec?.state == "completed" &&
                            this.props.prospec?.prospectedAt &&
                                <>
                                    le {" "} {getFormatedDateTime(this.props.prospec?.prospectedAt)}
                                </>
                        }
                    </span>
                    <button className="modal__close" onClick={() => this.props.setOpenModal(false)}>
                        <IcomoonReact iconSet={iconSet} icon="cross" />
                    </button>
                </header>
                <AlertBox type="info" title="Information" content="Choisissez une action pour modifier le statut d'une rue" />
                <select onChange={el => this.props.editProspection({ idEvent: this.props.prospec?.id, select: el.target.value })} className="modal__select">
                    <option selected disabled>-- Choisir une action --</option>
                    <option value="loading">Cette rue est en cours</option>
                    <option value="completed">Cette rue est faite</option>
                    {
                        this.props.prospec?.state != "completed" &&
                        this.props.prospec?.prospectedBy.length > 0 &&
                        this.props.prospec?.prospectedBy.filter(item => item.id == localStorage['user-id']).length > 0 == localStorage['user-id'] && <option value="unscribe">Je ne m'occupe plus de cette rue</option>
                    }
                </select>
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        edition: state.editProspection
    }
}

const mapDispatchToProps = {
    editProspection,
    getProspections,

}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProspection);