import React, { Component } from "react";

import bassee from "../assets/images/map/bassee.png";
import faugerit from "../assets/images/map/faugerit.png";
import pont from "../assets/images/map/pont.png";
import a1 from "../assets/images/map/f2r-1a.png";
import b1 from "../assets/images/map/f2r-1b.png";
import c1 from "../assets/images/map/f2r-1c.png";
import d1 from "../assets/images/map/f2r-1d.png";
import e1 from "../assets/images/map/f2r-1e.png";
import a2 from "../assets/images/map/f2r-2a.png";
import b2 from "../assets/images/map/f2r-2b.png";
import c2 from "../assets/images/map/f2r-2c.png";
import d2 from "../assets/images/map/f2r-2d.png";
import e2 from "../assets/images/map/f2r-2e.png";
import a3 from "../assets/images/map/f2r-3a.png";
import b3 from "../assets/images/map/f2r-3b.png";
import c3 from "../assets/images/map/f2r-3c.png";
import d3 from "../assets/images/map/f2r-3d.png";
import e3 from "../assets/images/map/f2r-3e.png";
import a4 from "../assets/images/map/f2r-4a.png";
import b4 from "../assets/images/map/f2r-4b.png";
import c4 from "../assets/images/map/f2r-4c.png";
import d4 from "../assets/images/map/f2r-4d.png";
import e4 from "../assets/images/map/f2r-4e.png";
import a5 from "../assets/images/map/f2r-5a.png";
import b5 from "../assets/images/map/f2r-5b.png";
import c5 from "../assets/images/map/f2r-5c.png";
import d5 from "../assets/images/map/f2r-5d.png";
import e5 from "../assets/images/map/f2r-5e.png";
import a6 from "../assets/images/map/f2r-6a.png";
import b6 from "../assets/images/map/f2r-6b.png";
import c6 from "../assets/images/map/f2r-6c.png";
import d6 from "../assets/images/map/f2r-6d.png";
import e6 from "../assets/images/map/f2r-6e.png";
import ModalProspection from "./events/ModalProspection";

class Map extends Component {
    state = {
        img: null,
        openModal: false,
        street: null,
        prospec: null
    }

    componentDidMount() {
        switch(this.props.map) {
            case "f2r-1a":
                this.setState({ img: a1 });
                break;
            case "f2r-1b":
                this.setState({ img: b1 });
                break;
            case "f2r-1c":
                this.setState({ img: c1 });
                break;
            case "f2r-1d":
                this.setState({ img: d1 });
                break;
            case "f2r-1e":
                this.setState({ img: e1 });
                break;
            case "f2r-2a":
                this.setState({ img: a2 });
                break;
            case "f2r-2b":
                this.setState({ img: b2 });
                break;
            case "f2r-2c":
                this.setState({ img: c2 });
                break;
            case "f2r-2d":
                this.setState({ img: d2 });
                break;
            case "f2r-2e":
                this.setState({ img: e2 });
                break;
            case "f2r-3a":
                this.setState({ img: a3 });
                break;
            case "f2r-3b":
                this.setState({ img: b3 });
                break;
            case "f2r-3c":
                this.setState({ img: c3 });
                break;
            case "f2r-3d":
                this.setState({ img: d3 });
                break;
            case "f2r-3e":
                this.setState({ img: e3 });
                break;
            case "f2r-4a":
                this.setState({ img: a4 });
                break;
            case "f2r-4b":
                this.setState({ img: b4 });
                break;
            case "f2r-4c":
                this.setState({ img: c4 });
                break;
            case "f2r-4d":
                this.setState({ img: d4 });
                break;
            case "f2r-4e":
                this.setState({ img: e4 });
                break;
            case "f2r-5a":
                this.setState({ img: a5 });
                break;
            case "f2r-5b":
                this.setState({ img: b5 });
                break;
            case "f2r-5c":
                this.setState({ img: c5 });
                break;
            case "f2r-5d":
                this.setState({ img: d5 });
                break;
            case "f2r-5e":
                this.setState({ img: e5 });
                break;
            case "f2r-6a":
                this.setState({ img: a6 });
                break;
            case "f2r-6b":
                this.setState({ img: b6 });
                break;
            case "f2r-6c":
                this.setState({ img: c6 });
                break;
            case "f2r-6d":
                this.setState({ img: d6 });
                break;
            case "f2r-6e":
                this.setState({ img: e6 });
                break;
            case "bassee":
                this.setState({ img: bassee });
                break;
            case "faugerit":
                this.setState({ img: faugerit });
                break;
            case "pont":
                this.setState({ img: pont });
                break;
        }
    }

    handleOpenModal({ street, prospec }) {
        this.setState({ prospec: prospec });
        this.setState({ street: street });
        this.setState({ openModal: true });
    }

    render() {
        return  <div className="map">
            <img src={this.state.img} className="map__background" />
            <div className="map__streets">
                <svg xmlns="http://www.w3.org/2000/svg" 
                className="map__street" viewBox="0 0 500 500">
                    {
                        this.props.paths.map(path =>
                        <g>
                            <title>{path.street}</title>
                            <path onClick={() => this.handleOpenModal({ street: path.street, prospec: path.prospec })} d={path.path} className={`map__path map__path--${path.state}`} />
                        </g>
                    )}
                </svg>
            </div>
            <ModalProspection
                prospec={this.state.prospec}
                openModal={this.state.openModal}
                setOpenModal={(el) => this.setState({ openModal: el })}
                street={this.state.street}
            />
        </div>
    }
}

export default Map;