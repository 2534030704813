import { Navigate } from "react-router-dom";

export const config = {
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials':'true',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Allow-Methods,Access-Control-Request-Headers',
    }
}

export const getDomain = "https://api-f2r.projet-matthew.fr";

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const isConnected = () => {
    if (!(localStorage && localStorage['credentials'] && localStorage['user-id'])) {
        return <Navigate to={'/'} state={{from: window.location.pathname}} replace={true} />
    } else {
        return true
    }
};

export const getFormatedDateTime = (datetime) => {
    let day = new Date(datetime).getDate() < 10 ?
        `0${new Date(datetime).getDate()}` :
        new Date(datetime).getDate();
    let monthExact = parseInt(new Date(datetime).getMonth()) + 1;
    let month = monthExact < 10 ? `0${monthExact}` : monthExact;
    let year = new Date(datetime).getFullYear();
    let hour = new Date(datetime).getHours();
    let min = new Date(datetime).getMinutes();

    return `${day}/${month}/${year} à ${hour}:${min}`;
}