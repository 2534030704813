import {
    ADD_USER,
    LOG_USER,
    FORGOT_PASSWORD,
} from "../actions/action-types";

// Check login user
export function LoginCheckReducer(state = {
    disabled: false,
    alert: {}
}, action) {
    switch (action.type) {
        case LOG_USER:
            return action.payload;
        default:
            return state
    }
}

// Add user
export function AddUserReducer(state = {
    disabled: false,
    alert: {}
}, action) {
    switch (action.type) {
        case ADD_USER:
            return action.payload;
        default:
            return state;
    }
}

// Forgot password
export function ForgotPasswordReducer(state = {
    disabled: false,
    alert: {},
    step: 1
}, action) {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return action.payload;
        default:
            return state;
    }
}

