import axios from "axios";
import { config, getDomain } from "../../helpers";
import { EDIT_PROSPECTION, GENERATE_PROSPECTION, GET_ALL_PROSPECTIONS, GET_PROSPECTIONS } from "../action-types";

// Générer la prospection=
export function generateProspection({ idEvent }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/prospection/generate`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "prospection-event": idEvent
        }, config)
            .then(res => {
                let alert = {};

                if (res.data.status == "success") {
                    alert = {type: 'success', title: 'Prospection créée', content: "La prospection a bien été générée"};
                } else {
                    switch (res.data.type) {
                        case 'empty':
                            alert = {
                                type: 'error',
                                title: 'Erreur formulaire', 
                                content: "Veuillez remplir tous les champs obligatoires"
                            }
                            break;
                        case 'prospection-already-exist':
                            alert = {
                                type: 'error',
                                title: 'Prospection existante', 
                                content: "La prospection de cet évènement a déjà débutté"
                            }
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: GENERATE_PROSPECTION,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}

// Obtention de toutes les prospections
export function getAllProspections() {
    return function (dispatch) {
        axios.post(`${getDomain}/api/prospections-all`, {}, config)
            .then(res => {
                let alert = {};
                let events = [];

                if (res.data.status == "success") {
                    alert = {
                        type: "success",
                        title: "Evènements obtenus",
                        content: "Tous les évènements ont été obtenus"
                    }
                    events = res.data.events
                } else {
                    alert = {
                        type: "error",
                        title: "Erreur survenue",
                        content: "Une erreur est survenue, veuillez contacter Matthew"
                    }
                }

                dispatch({
                    type: GET_ALL_PROSPECTIONS,
                    payload: {
                        alert: alert,
                        events: events
                    }
                })
            })
    }
}

// Obtenir les prospections d'un évènement
export function getProspections({ idEvent }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/prospections`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "prospection-event": idEvent
        }, config)
            .then(res => {
                let alert = {};
                let prospections = [];
                let totalQuarter = [];
                let event = {};

                if (res.data.status == "success") {
                    alert = {
                        type: "success",
                        title: "Données obtenues",
                        content: "Toutes les données ont été obtenues"
                    };
                    prospections = res.data.prospections;
                    totalQuarter = res.data.totalQuarter;
                    event = res.data.event;
                } else {
                    alert = {
                        type: "error",
                        title: "Erreur survenue",
                        content: "Une erreur est survenue, veuillez contacter Matthew"
                    }
                }

                dispatch({
                    type: GET_PROSPECTIONS,
                    payload: {
                        alert: alert,
                        totalQuarter: totalQuarter,
                        prospections: prospections,
                        event: event
                    }
                })
            })
    }
}

// Modifier état prospection
export function editProspection({ idEvent, select }) {
    return function (dispatch) {
        axios.post(`${getDomain}/api/prospection/edit/${idEvent}`, {
            "user-id": localStorage['user-id'],
            "user-credentials": localStorage['credentials'],
            "prospection-state": select,
            "prospection-by": localStorage['user-id']
        }, config)
            .then(res => {
                let alert = {};

                if (res.data.status == "success") {
                    alert = {
                        type: "success",
                        title: "Données modifiées",
                        content: "L'état de cette rue a été modifiée"
                    };
                } else {
                    switch (res.data.type) {
                        case 'empty':
                            alert = {
                                type: 'error',
                                title: 'Erreur formulaire', 
                                content: "Veuillez remplir tous les champs obligatoires"
                            }
                            break;
                        case 'street-inexist':
                            alert = {
                                type: 'error',
                                title: 'Rue inexistante', 
                                content: "La rue n'existe pas"
                            }
                            break;
                        case 'credentials':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        case 'not-right':
                            alert = {
                                type: 'error',
                                title: 'Erreur d\'accès', 
                                content: "Vous n'avez pas les droits nécessaires pour effectuer cette action"
                            }
                            break;
                        default:
                            alert = {
                                type: 'error',
                                title: 'Erreur survenue', 
                                content: "Une erreur est survenue, veuillez contacter Matthew"
                            }
                            break;
                    }
                }

                dispatch({
                    type: EDIT_PROSPECTION,
                    payload: {
                        alert: alert
                    }
                })
            })
    }
}