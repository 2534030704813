import React, { Component } from "react";
import { connect } from "react-redux";
import { loginCheck } from "../../actions/action/user";
import AlertBox from "../AlertBox";
import logo from "../../assets/images/logo/logo.png";

class SignIn extends Component {
    state = {
        formState: {
            email: null,
            password: null,
        },
        disabled: false,
        alert: null
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.alert !== undefined &&
            Object.keys(this.props.alert).length > 0 &&
            this.props.alert !== this.state.alert
        ) {
            alert(this.props.alert.content);
            this.setState({alert: this.props.alert});
        }

        if (
            this.props.disabled !== undefined &&
            this.props.disabled !== this.state.disabled
        ) {
            this.setState({disabled: this.props.disabled});
        }
    }

    handleFormChange = (e) => {
        this.setState({ formState: {
            ...this.state.formState,
            [e.target.name]: e.target.value
        }})
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true });
        this.setState({ alert: {type: 'warning', title: 'Attente', content: 'Vérification de vos données en cours...'}})
        this.props.loginCheck({ form: this.state.formState });
    }

    render() {
        return <section
            className={`registration ${
                this.props.stepRegistr == "forgot" ?
                    'registration--right' :
                    this.props.stepRegistr == "inscri" &&
                        'registration--left'
            }`}
        >
            <img src={logo} alt="Logo de F2R en fête" className="registration__img" />
            {this.state.alert && Object.keys(this.state.alert).length > 0 && <AlertBox {...this.state.alert} />}
            <h2 className="registration__ttl">Connectez-vous</h2>
            <p className="registration__help">* champs obligatoires</p>
            <form className="form">
                {/** Email */}
                <div className="form__formgrp">
                    <label htmlFor="email" className="form__label">Adresse E-mail*</label>
                    <input
                        type="mail"
                        placeholder="exemple@mail.com"
                        id="email"
                        name="email"
                        className="form__input"
                        value={this.state.formState.email}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>
                {/** Mot de passe */}
                <div className="form__formgrp">
                    <label htmlFor="password" className="form__label">Mot de passe*</label>
                    <input
                        type="password"
                        placeholder="Mot de passe"
                        id="password"
                        name="password"
                        className="form__input"
                        value={this.state.formState.password}
                        onChange={this.handleFormChange} 
                        disabled={this.state.disabled}
                    />
                </div>

                <button type="submit" className="btn background" onClick={this.handleSubmit}>Je me connecte</button>
            </form>
            <div className="form__listBtn form__listBtn--registration">
                <button className="btn btn--secondary" onClick={() => this.props.setStep("forgot")}>Problème de connexion ?</button>
                <button className="btnlink" onClick={() => this.props.setStep("inscri")}>Vous n'avez pas de compte ? Inscrivez-vous</button>
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return state.loginCheck
}

const mapDispatchToProps = {
    loginCheck
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);