import React, { Component } from "react";
import { connect } from "react-redux";
import { getStreets, deleteStreet } from "../../actions/action/street";
import { Link } from "react-router-dom";
import iconSet from "../../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import AlertBox from "../../components/AlertBox";
import { isConnected } from "../../helpers";
import map from "../../assets/images/map/f2r-1b.png";

class Streets extends Component {
    state = {
        alert: {
            type: 'warning',
            title: 'Attente',
            content: 'Recherche des rues...'
        },
        streets: []
    }

    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
        this.props.getStreets();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.streets.alert !== undefined &&
            Object.keys(this.props.streets.alert).length > 0 &&
            this.props.streets.alert !== this.state.alert
        ) {
            alert(this.props.streets.alert.content);
            this.setState({alert: this.props.streets.alert});
            this.setState({streets: this.props.streets.streets});
        }
        if (
            this.props.delete.alert !== undefined &&
            Object.keys(this.props.delete.alert).length > 0 &&
            this.props.delete.alert !== this.state.alert
        ) {
            alert(this.props.delete.alert.content);
            this.setState({alert: this.props.delete.alert});
        }
    }

    handleDelete(id) {
        if (window.confirm("Souhaitez-vous supprimer définitivement cette rue ?")) {
            this.props.deleteStreet({id: id});
        }
    }

    render() {
        return <section className="streets">
            {isConnected()}
            <header className="streets__header">
                <h1 className="streets__ttl">Les rues</h1>
                <nav className="streets__menu">
                    <Link to="/ajouter-rue" className="btn btn--secondary">
                        <IcomoonReact iconSet={iconSet} icon="plus" />
                        Ajouter une rue
                    </Link>
                </nav>
            </header>
            {this.state.alert && Object.keys(this.state.alert).length > 0 && this.state.alert.type !== "success" && <AlertBox {...this.state.alert} />}
            <table className="streets__table">
                <thead className="streets__thead">
                    <tr className="streets__tr">
                        <td className="streets__td">Id</td>
                        <td className="streets__td">Nom</td>
                        <td className="streets__td">Options</td>
                    </tr>
                </thead>
                <tbody className="streets__tbody">
                    {this.state.streets.map((street, index) =>
                        <tr className="streets__tr" key={index}>
                            <td className="streets__td">{street.id}</td>
                            <td className="streets__td">{street.name}</td>
                            <td className="streets__td">
                                <nav className="streets__btns">
                                    <Link to={`/rue/${street.id}`} className="streets__btn">
                                        <IcomoonReact iconSet={iconSet} icon="eye" />
                                    </Link>
                                    <Link to={`/editer-rue/${street.id}`} className="streets__btn">
                                        <IcomoonReact iconSet={iconSet} icon="pencil" />
                                    </Link>
                                    <button onClick={() => this.handleDelete(street.id)} className="streets__btn">
                                        <IcomoonReact iconSet={iconSet} icon="bin" />
                                    </button>
                                </nav>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        streets: state.getStreets,
        delete: state.deleteStreet
    }
}

const mapDispatchToProps = {
    getStreets,
    deleteStreet
}

export default connect(mapStateToProps, mapDispatchToProps)(Streets);