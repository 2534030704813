import React, { Component } from "react";
import { connect } from "react-redux";
import { getEvents } from "../actions/action/events";
import { Link } from "react-router-dom";
import iconSet from "../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import Event from "../components/events/Event";
import AlertBox from "../components/AlertBox";
import { isConnected } from "../helpers";

class Events extends Component {
    state = {
        alert: {
            type: 'warning',
            title: 'Attente',
            content: 'Recherche des évènements...'
        },
        events: []
    }

    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
        this.props.getEvents();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.alert !== undefined &&
            Object.keys(this.props.alert).length > 0 &&
            this.props.alert !== this.state.alert
        ) {
            alert(this.props.alert.content);
            this.setState({alert: this.props.alert});
            this.setState({events: this.props.events});
        }
    }

    render() {
        return <div className="events">
            {isConnected()}
            <header className="events__header">
                <h1 className="events__ttl">Les évènements</h1>
                <nav className="events__menu">
                    <Link to="/ajouter-evenement" className="btn btn--secondary">
                        <IcomoonReact iconSet={iconSet} icon="plus" />
                        Ajouter un évènements
                    </Link>
                </nav>
            </header>
            {this.state.alert && Object.keys(this.state.alert).length > 0 && this.state.alert.type !== "success" && <AlertBox {...this.state.alert} />}
            <ul className="events__list">
                {/* Composant évènement */}
                {
                    this.state.events
                        .filter(event => new Date(event.eventAt.date) >= new Date())
                        .map((event, index) => <Event event={event} key={index}/>)
                }
                {
                    this.state.events
                        .filter(event => new Date(event.eventAt.date) < new Date())
                        .map((event, index) => <Event event={event} key={index}/>)
                }
            </ul>
        </div>
    }
}

const mapStateToProps = state => {
    return state.getEvents
}

const mapDispatchToProps = {
    getEvents
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);