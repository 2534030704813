import React, { Component } from "react";
import iconSet from "../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { Link } from "react-router-dom";
import { editEvent, getEvent } from "../actions/action/events";
import { connect } from "react-redux";
import AlertBox from "../components/AlertBox";
import { isConnected } from "../helpers";

class EventEdit extends Component {
    state = {
        form: {
            title: null,
            type: null,
            description: null,
            place: null,
            at: null
        },
        getEvent: false,
        disabled: false,
        alert: {
            type: 'warning',
            title: 'Attente',
            content: 'Récupération des données...'
        },
        id: null,
        firstAlert: false
    };

    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
        const pathname = window.location.pathname.split("/");
        const id = pathname[pathname.length - 1];
        this.setState({id: id});
        this.props.getEvent({id: id});
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.eventDetails.alert !== undefined &&
            Object.keys(this.props.eventDetails.alert).length > 0 &&
            this.props.eventDetails.alert !== this.state.alert &&
            this.state.firstAlert == false
        ) {
            alert(this.props.eventDetails.alert.content);

            this.setState({alert: this.props.eventDetails.alert});
            this.setState({firstAlert: true});
        }
        if (
            this.props.editEventReducer.alert !== undefined &&
            Object.keys(this.props.editEventReducer.alert).length > 0 &&
            this.props.editEventReducer.alert !== this.state.alert
        ) {
            alert(this.props.editEventReducer.alert.content);
            this.setState({alert: this.props.editEventReducer.alert});
        }

        if (
            this.props.disabled !== undefined &&
            this.props.disabled !== this.state.disabled
        ) {
            this.setState({disabled: this.props.disabled});
        }
        if (
            this.state.getEvent == false &&
            this.props.eventDetails !== undefined &&
            Object.keys(this.props.eventDetails.alert).length > 0
        ) {
            this.setState({ getEvent: true });
            let date = new Date(this.props.eventDetails.event.eventAt?.date);
            this.setState({
                form: {
                        title: this.props.eventDetails.event.title,
                        description: this.props.eventDetails.event.description,
                        place: this.props.eventDetails.event.place,
                        at: date == "Invalid Date" ? null : date?.toISOString(),
                        type: this.props.eventDetails.event.type
                    }
            })
        }
    }

    handleFormChange = (e) => {
        this.setState({ form: {
            ...this.state.form,
            [e.target.name]: e.target.value
        }})
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true });
        this.setState({
            alert: {
                type: 'warning',
                title: 'Attente',
                content: 'Enregistrement des données en cours...'
            }
        });
        this.props.editEvent({ form: this.state.form, id: this.state.id });
    }

    render() {
        return <section className="events">
            {isConnected()}
            <header className="events__header">
                <h1 className="events__ttl">Editer un évènement</h1>
                <Link to="/evenements" className="btn">
                    <IcomoonReact iconSet={iconSet} icon="arrow-left2" />
                    Revenir en arrière
                </Link>
            </header>
            <div className="events__content">
                {this.state.alert && Object.keys(this.state.alert).length > 0 && <AlertBox {...this.state.alert} />}
                <p className="form__help">* champs obligatoires</p>
                <form className="form">
                    {/** Titre */}
                    <div className="form__formgrp">
                        <label htmlFor="title" className="form__label">Titre *</label>
                        <input
                            type="text"
                            placeholder="Titre"
                            id="title"
                            name="title"
                            className="form__input"
                            value={this.state.form.title}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                            required
                        />
                    </div>
                    {/** Type */}
                    <div className="form__formgrp">
                        <label htmlFor="type" className="form__label">Type *</label>
                        <select
                            className="form__select"
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                            id="type"
                            name="type"
                            required
                        >
                            <option
                                selected={this.state.form.type == null}
                                disabled
                            >-- Choisir un type --</option>
                            <option
                                selected={this.state.form.type == "Réunion"}
                                value="Réunion"
                            >Réunion</option>
                            <option
                                selected={this.state.form.type == "Évènement"}
                                value="Évènement"
                            >Évènement</option>
                            <option
                                selected={this.state.form.type == "Autre"}
                                value="Autre"
                            >Autre</option>
                        </select>
                    </div>
                    {/** Lieu */}
                    <div className="form__formgrp">
                        <label htmlFor="place" className="form__label">Lieu</label>
                        <input
                            type="text"
                            placeholder="1 rue du lieu, 79270 Saint Symphorien"
                            id="place"
                            name="place"
                            className="form__input"
                            value={this.state.form.place}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    {/** Description */}
                    <div className="form__formgrp">
                        <label htmlFor="description" className="form__label">Description</label>
                        <textarea
                            placeholder="Description"
                            id="description"
                            name="description"
                            className="form__input"
                            value={this.state.form.description}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        ></textarea>
                    </div>
                    {/** Date et heure */}
                    <div className="form__formgrp">
                        <label htmlFor="at" className="form__label">Date et heure</label>
                        <input
                            type="datetime-local"
                            id="at"
                            name="at"
                            className="form__input"
                            value={this.state.form.at}
                            onChange={this.handleFormChange} 
                            disabled={this.state.disabled}
                        />
                    </div>
                    <div className="form__listBtn form__listBtn--row">
                        <Link to="/evenements" className="btn btn--secondary">
                            <IcomoonReact iconSet={iconSet} icon="cross" />
                            Annuler
                        </Link>
                        <button type="submit" onClick={this.handleSubmit} className="btn background">
                            <IcomoonReact iconSet={iconSet} icon="checkmark" />
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        editEventReducer: state.editEvent,
        eventDetails: state.getEvent
    }
}

const mapDispatchToProps = {
    editEvent,
    getEvent
}

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);