import { ADD_STREET, DEL_STREET, EDIT_STREET, GET_STREET, GET_STREETS } from "../actions/action-types";

// Get streets
export function GetStreetsReducer(state = {
    alert: {},
    streets: []
}, action) {
    switch (action.type) {
        case GET_STREETS:
            return action.payload;
        default:
            return state
    }
}

// Get street
export function GetStreetReducer(state = {
    alert: {},
    street: {}
}, action) {
    switch (action.type) {
        case GET_STREET:
            return action.payload;
        default:
            return state
    }
}

// Add street
export function AddStreetReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case ADD_STREET:
            return action.payload;
        default:
            return state
    }
}

// Edit street
export function EditStreetReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case EDIT_STREET:
            return action.payload;
        default:
            return state
    }
}

// Delete street
export function DeleteStreetReducer(state = {
    alert: {}
}, action) {
    switch (action.type) {
        case DEL_STREET:
            return action.payload;
        default:
            return state
    }
}
