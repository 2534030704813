import "./assets/scss/app.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import { Component, createContext, useEffect, useReducer } from "react";
import { loginCheck, findUser } from './actions/action/user';
import { connect } from 'react-redux';
import { getDomain } from "./helpers";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import Event from "./pages/Event";
import EventAdd from "./pages/EventAdd";
import EventEdit from "./pages/EventEdit";
import Streets from "./pages/street/Streets";
import Street from "./pages/street/Street";
import StreetAdd from "./pages/street/StreetAdd";
import StreetEdit from "./pages/street/StreetEdit";
import Prospections from "./pages/prospection/Prospections";
import Prospection from "./pages/prospection/Prospection";
import Contact from "./pages/Contact";
import PolConf from "./pages/PolConf";
import Mentions from "./pages/Mentions";

export const AuthContext = createContext();

class App extends Component {
  state = {
    state: {
      loading: false,
      error: false,
      user: null
    },
    pageActu: null
  }

  componentDidMount() {
    this.isLogin();
  };

  /*
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'login_start':
        return {
          loading: true,
          error: false,
          user: {
            lastname: null,
            firstname: null
          }
        }
      case 'login_success':
        return {
          loading: false,
          error: false,
          user: action.payload
        }
      case 'logout_success':
        return {
          loading: false,
          error: false,
          user: null
        }
      case 'login_error':
        return {
          loading: false,
          error: true,
          user: {
            lastname: null,
            firstname: null
          }
        }
    }
  }, {
    loading: false,
    error: false,
    user: null
  });
  */
  isLogin = () => {
    if (localStorage.credentials && localStorage.user) {
      return true;
      // TODO: Voir si ça marche
    }
  }

  login = (credentials) => {
    this.setState({
      state: {
        loading: true,
        error: false,
        user: {
          lastname: null,
          firstname: null
        }
      }
    });
    this.props.loginCheck(credentials);
  }

  handleSetPageActive = (el) => {
    this.setState({ pageActu: el });
  }

  render() {
    return (
      <AuthContext.Provider value={[
        this.state.state,
        {
          login: this.login,
          // logout: this.logout
        }
      ]}>
        <BrowserRouter>
          <Layout pageActu={this.state.pageActu}>
            <Routes>
              <Route path="*" exact element={<NotFound />} />
              <Route path="/" exact element={<Home />} />

              <Route path="/dashboard" element={<Dashboard setPageActive={this.handleSetPageActive} currentPage="dashboard" />} />
            
              <Route path="/contact" element={<Contact />} />
              <Route path="/politiques-de-confidentialite" element={<PolConf />} />
              <Route path="/mentions-legales" element={<Mentions />} />

              <Route path="/evenements" element={<Events setPageActive={this.handleSetPageActive} currentPage="events" />} />
              <Route path="/evenement/:id" element={<Event setPageActive={this.handleSetPageActive} currentPage="events" />} />
              <Route path="/ajouter-evenement" element={<EventAdd setPageActive={this.handleSetPageActive} currentPage="events" />} />
              <Route path="/editer-evenement/:id" element={<EventEdit setPageActive={this.handleSetPageActive} currentPage="events" />} />
            
              <Route path="/rues" element={<Streets setPageActive={this.handleSetPageActive} currentPage="streets" />} />
              <Route path="/rue/:id" element={<Street setPageActive={this.handleSetPageActive} currentPage="streets" />} />
              <Route path="/ajouter-rue" element={<StreetAdd setPageActive={this.handleSetPageActive} currentPage="streets" />} />
              <Route path="/editer-rue/:id" element={<StreetEdit setPageActive={this.handleSetPageActive} currentPage="streets" />} />
            
              <Route path="/prospections" element={<Prospections setPageActive={this.handleSetPageActive} currentPage="prospections" />} />
              <Route path="/prospection/:id" element={<Prospection setPageActive={this.handleSetPageActive} currentPage="prospections" />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </AuthContext.Provider>
    )
  }
}

const mapStateToProps = state => {
  return state.loginCheck
}

const mapDispatchToProps = {
  loginCheck
}

export default connect(mapStateToProps, mapDispatchToProps)(App);