import React, { Component } from "react";
import { connect } from "react-redux";
import { forgotPasswordFirstStep, forgotPasswordSecondStep } from "../../actions/action/user";
import AlertBox from "../AlertBox";
import logo from "../../assets/images/logo/logo.png";

class ForgetPassword extends Component {
    state = {
        formState: {
            email: null,
        },
        formUpdate: {
            code: null,
            password: null,
            confirmPassword: null
        },
        disabled: false,
        alert: null,
        step: 1
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.alert !== undefined &&
            Object.keys(this.props.alert).length > 0 &&
            this.props.alert !== this.state.alert
        ) {
            alert(this.props.alert.content);
            this.setState({alert: this.props.alert});
        }

        if (
            this.props.disabled !== undefined &&
            this.props.disabled !== this.state.disabled
        ) {
            this.setState({disabled: this.props.disabled});
        }

        if (
            this.props.step !== undefined &&
            this.props.step !== this.state.step
        ) {
            this.setState({step: this.props.step});
        }
    }

    handleFormChange = (e) => {
        this.setState({ formState: {
            ...this.state.formState,
            [e.target.name]: e.target.value
        }})
    }

    handleUpdateFormChange = (e) => {
        this.setState({ formUpdate: {
            ...this.state.formUpdate,
            [e.target.name]: e.target.value
        }})
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true });
        this.setState({ alert: {type: 'warning', title: 'Attente', content: 'Vérification de vos données en cours...'}})
        this.props.forgotPasswordFirstStep({ form: this.state.formState });
    }

    handleUpdateSubmit = (ev) => {
        ev.preventDefault();
        this.setState({ disabled: true });
        this.setState({ alert: {type: 'warning', title: 'Attente', content: 'Vérification de vos données en cours...'}})
        this.props.forgotPasswordSecondStep({ form: this.state.formUpdate, formPrev: this.state.formState });
    }

    render() {
        return <section
            className={`registration ${
                this.props.stepRegistr == "inscri" ?
                    'registration--leftdouble' :
                    this.props.stepRegistr == "co" &&
                        'registration--left'
            }`}
        >
            <img src={logo} alt="Logo de F2R en fête" className="registration__img" />
            {this.state.alert && Object.keys(this.state.alert).length > 0 && <AlertBox {...this.state.alert} />}
            <h2 className="registration__ttl">Mot de passe oublié</h2>
            {
                this.state.step == 1 ?
                    <form className="form">
                        <p className="form__help">* champs obligatoires</p>
                        {/** Email */}
                        <div className="form__formgrp">
                            <label htmlFor="email" className="form__label">Adresse E-mail *</label>
                            <input
                                type="mail"
                                placeholder="exemple@mail.com"
                                id="email"
                                name="email"
                                className="form__input"
                                value={this.state.formState.email}
                                onChange={this.handleFormChange} 
                                disabled={this.state.disabled}
                            />
                        </div>

                        <button type="submit" className="btn background" onClick={this.handleSubmit}>Valider mon adresse e-mail</button>
                    </form> :
                    <form className="form">
                        <p className="form__help">Nb: Veuillez vérifier votre boîte mail et vos spams</p>
                        <p className="form__help">* champs obligatoires</p>
                        {/** Code reçu */}
                        <div className="form__formgrp">
                            <label htmlFor="code" className="form__label">Code reçu *</label>
                            <input
                                type="text"
                                placeholder="XXXXXX"
                                id="code"
                                name="code"
                                className="form__input"
                                value={this.state.formState.code}
                                onChange={this.handleUpdateFormChange} 
                                disabled={this.state.disabled}
                            />
                        </div>
                        {/** Mot de passe */}
                        <div className="form__formgrp">
                            <label htmlFor="password" className="form__label">Mot de passe *</label>
                            <input
                                type="password"
                                placeholder="Mot de passe"
                                id="password"
                                name="password"
                                className="form__input"
                                value={this.state.formState.password}
                                onChange={this.handleUpdateFormChange} 
                                disabled={this.state.disabled}
                            />
                        </div>
                        {/** Confirmation mot de passe */}
                        <div className="form__formgrp">
                            <label htmlFor="confirmPassword" className="form__label">Confirmation mot de passe *</label>
                            <input
                                type="password"
                                placeholder="Confirmation du mot de passe"
                                id="confirmPassword"
                                name="confirmPassword"
                                className="form__input"
                                value={this.state.formState.confirmPassword}
                                onChange={this.handleUpdateFormChange} 
                                disabled={this.state.disabled}
                            />
                        </div>
        
                        <button type="submit" className="btn background" onClick={this.handleUpdateSubmit}>Modifier mon mot de passe</button>
                    </form>
            }
            <div className="form__listBtn form__listBtn--registration">
                <button className="btn btn--secondary" onClick={() => this.props.setStep("co")}>Connectez-vous</button>
                <button className="btnlink" onClick={() => this.props.setStep("inscri")}>Vous n'avez pas de compte ? Inscrivez-vous</button>
            </div>            
        </section>
    }
}

const mapStateToProps = state => {
    return state.forgotPassword
}

const mapDispatchToProps = {
    forgotPasswordFirstStep,
    forgotPasswordSecondStep
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);