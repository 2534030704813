import React, { Component } from "react";
import { connect } from "react-redux";
import iconSet from "../assets/icomoon/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import AlertBox from "../components/AlertBox";
import { Link } from "react-router-dom";
import { getEvent, deleteEvent } from "../actions/action/events";
import { generateProspection } from "../actions/action/prospection";
import { getFormatedDateTime, isConnected } from "../helpers";

class Event extends Component {
    state = {
        alert: {
            type: 'warning',
            title: 'Attente',
            content: 'Récupération des données...'
        },
        paramsOpen: false,
        event: {}
    }

    componentDidMount() {
        this.props.setPageActive(this.props.currentPage);
        const pathname = window.location.pathname.split("/");
        const id = pathname[pathname.length - 1];
        this.props.getEvent({id: id});
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.event.alert !== undefined &&
            Object.keys(this.props.event.alert).length > 0 &&
            this.props.event.alert !== this.state.alert
        ) {
            alert(this.props.event.alert.content);
            this.setState({alert: this.props.event.alert});
            this.setState({event: this.props.event.event});
        }
        if (
            this.props.generateProspection.alert !== undefined &&
            Object.keys(this.props.generateProspection.alert).length > 0 &&
            this.props.generateProspection.alert !== this.state.alert
        ) {
            alert(this.props.generateProspection.alert.content);
            this.setState({alert: this.props.generateProspection.alert});
        }
    }

    handleDeleteEvent(id) {
        if (window.confirm("Souhaitez-vous supprimer définitivement cet évènement ?")) {
            this.props.deleteEvent({id: id});
        }
    }
    
    render() {
        return <section className="events">
            {isConnected()}
            <header className="events__header">
                <h1 className="events__ttl">{this.state.event.title}</h1>
                {
                    this.props.event.event.eventAt != null &&
                        <span className="events__date">Le <strong>{getFormatedDateTime(this.props.event.event.eventAt.date)}</strong></span>
                }
                <span className="events__badge background">{this.state.event.type}</span>
                {
                    this.props.event.event.isGrade ? 
                        <button className="events__btnparams" onClick={() => this.setState({paramsOpen: !this.state.paramsOpen})}>
                            <IcomoonReact iconSet={iconSet} icon="dots-horizontal-triple" />
                        </button> :
                        ""
                }
                <Link to="/evenements" className="events__back">
                    <IcomoonReact iconSet={iconSet} icon="arrow-left2" />
                </Link>
            </header>
            {this.state.alert && Object.keys(this.state.alert).length > 0 && this.state.alert.type !== "success" && <AlertBox {...this.state.alert} />}
            <ul className="events__content">
                <li className="events__infos"><span>Lieux : </span><strong>{this.state.event.place}</strong></li>
                <li className="events__infos"><span>Description : </span><strong>{this.state.event.description}</strong></li>
            </ul>
            {
                this.props.event.event.isGrade ? 
                    <nav className={`events__params background ${this.state.paramsOpen && 'events__params--open'}`}>
                        {
                            this.props.event.event.hasProspection ?
                                <Link to={`/prospection/evenement/${this.props.event.event.id}`} className="event__btn">
                                    <IcomoonReact iconSet={iconSet} icon="map" />
                                    Prospecter
                                </Link> :
                                <button onClick={() => this.props.generateProspection({idEvent: this.props.event.event.id })} className="event__btn">
                                    <IcomoonReact iconSet={iconSet} icon="map" />
                                    Lancer la prospection
                                </button>
                        }
                        <Link to={`/editer-evenement/${this.props.event.event.id}`} className="events__btn">
                            <IcomoonReact iconSet={iconSet} icon="pencil" />
                            Modifier
                        </Link>
                        <button onClick={() => this.handleDeleteEvent(this.props.event.event.id)} className="events__btn">
                            <IcomoonReact iconSet={iconSet} icon="bin" />
                            Supprimer
                        </button>
                    </nav> :
                    ""
            }
        </section>
    }
}

const mapStateToProps = state => {
    return {
        event: state.getEvent,
        generateProspection: state.generateProspection
    }
}

const mapDispatchToProps = {
    getEvent,
    deleteEvent,
    generateProspection
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);