import { combineReducers } from "redux";
import { AddUserReducer, ForgotPasswordReducer, LoginCheckReducer } from "./user";
import { AddEventReducer, DeleteEventReducer, EditEventReducer, GetEventReducer, GetEventsReducer } from "./events";
import { AddStreetReducer, DeleteStreetReducer, EditStreetReducer, GetStreetReducer, GetStreetsReducer } from "./streets";
import { EditProspectionReducer, GenerateProspectionReducer, GetAllProspections, GetProspections } from "./prospection";

const rootReducer = combineReducers({
    // User
    loginCheck: LoginCheckReducer,
    addUser: AddUserReducer,
    forgotPassword: ForgotPasswordReducer,
    // Events
    getEvents: GetEventsReducer,
    getEvent: GetEventReducer,
    addEvent: AddEventReducer,
    editEvent: EditEventReducer,
    deleteEvent: DeleteEventReducer,
    // Street
    getStreets: GetStreetsReducer,
    getStreet: GetStreetReducer,
    addStreet: AddStreetReducer,
    editStreet: EditStreetReducer,
    deleteStreet: DeleteStreetReducer,
    // Prospection:
    generateProspection: GenerateProspectionReducer,
    getAllProspections: GetAllProspections,
    getProspections: GetProspections,
    editProspection: EditProspectionReducer
});

export default rootReducer;